import React, { Component } from 'react';
import { Col, FormGroup, FormText, FormFeedback, Label, Input, ButtonGroup, Button, InputGroup } from 'reactstrap';
import * as moment from 'moment';
import PasswordStrengthBar from 'react-password-strength-bar';


export function reactStateToDoc(state) {
  /** remove all properties starting with _ **/
  var doc = Object.assign({}, state);
  for (var k in doc) {
    if (k.startsWith("_") && (k !== "_id")) {
      delete doc[k];
    }
  }
  return doc
}


export default class FormBase extends (Component) {
  PASSWORD_SCORE = 3;

  validateRequired(name, errors) {
    if (!this.state[name]) {
      errors[name] = "This field is required";
    }
  }

  validatePasswords(errors) {
    if (this.state._passwordScore < this.PASSWORD_SCORE) {
      errors['password'] = "Your password is too weak. Make it longer or use a wider mix of characters: uppercase, lowercase, numbers or special characters such as *+-/$%#@.!, etc.";
    }
    this.validateRequired('password', errors);
    if (this.state.password !== this.state.passwordRepeat) {
      errors.passwordRepeat = "Passwords don't match";
    }
  }

  validateEmail(name, errors) {
    if (!this.state[name]) {
      errors[name] = 'Email address is required';
    } else if (!/\S+@\S+\.\S+/.test(this.state[name])) {
      errors[name] = 'Email address is invalid';
    }
  }

  validationSuccessful(errors) {
    return (Object.keys(errors).length === 0);
  }

  reactStateToDoc() {
    return reactStateToDoc(this.state);
  }

  extractInputValue(event) {
    const target = event.target;
    var value = target.value;

    if (target.multiple) {
      // multi-select - prevent the need for crtl or shift
      const values = this.state[target.name] || [];
      value = parseInt(value); // this works only for numeric ID's
      if (values.indexOf(value) >= 0) {
        // remove from list
        values.splice(values.indexOf(value), 1);
      }
      else {
        // add to list
        values.push(value);
      }
      value = values;
    }

    else if (target.type === 'checkbox') {
      // a simple true/false checkbox
      value = target.checked;
    }
    else if (target.type === 'date') {
      value = moment(target.value).format("YYYY-MM-DD");
    }
    else if (target.type === 'number') {
      value = parseFloat(target.value);
    }

    return value;
  }

  handleInputChange(event, callback) {
    const target = event.target;
    const name = target.name;
    this.setState({
      [name]: this.extractInputValue(event)
    }, callback);
  }

  onError(errors) {
    // nothing special by default
  }

  handleSubmit(event) {
    event.preventDefault();

    const errors = this.validate();
    this.setState({_validation: errors});

    if (this.validationSuccessful(errors)) {
      this.onSubmit();
    }
    else {
      this.onError(errors);
    }
  }

  _renderInput(props) {
    const inputProps = {
      value: props.value || this.state[props.name] || '',
    };

    if (props.type === 'checkbox') {
      inputProps.value = 'true';
      inputProps.checked = !!this.state[props.name];
    }

    if (props.type === "select") {
      return (
        <Input type="select" name={props.name} id={props.name}
          className={props.inputClassName}
          invalid={!!this.state._validation[props.name]}
          onChange={this.handleInputChange.bind(this)}
          disabled={props.disabled}
          multiple={props.multiple}
          value={this.state[props.name] || (props.multiple ? [] : '')}
        >
          {props.emptyOption && <option>{props.emptyOption}</option>}
          {props.options.map(e => (
            <option key={e.id} value={e.id}>{e.name}</option>
          ))}
        </Input>
      );
    }

    return (
      <Input type={props.type} name={props.name} id={props.name}
        className={props.inputClassName}
        placeholder={props.placeholder}
        invalid={!!this.state._validation[props.name]}
        onChange={props.onChange || this.handleInputChange.bind(this)}
        disabled={props.disabled}
        {...inputProps}
      />
    );
  }

  PasswordStrengthBar = (props) => {
    return (
      <PasswordStrengthBar
        password={this.state.password}
        scoreWordClassName={this.state._passwordScore < this.PASSWORD_SCORE ? "text-danger" : "text-success"}
        scoreWordStyle={{fontSize: "85%"}}
        minLength={8}
        scoreWords={['weak', 'weak', 'better', 'good', 'strong']}
        onChangeScore={(score) => this.setState({_passwordScore: score})}
      />
    );
  }

  FormInput = (props) => {
    return (
      <FormGroup row>
        <Label for={props.name} sm={2}>{props.label}</Label>
        <Col sm={10} className="text-left">
          <InputGroup>
            {(props.type !== 'custom') && this._renderInput(props)}
            {props.inputGroup && props.children}
          </InputGroup>
          {!props.inputGroup && props.children}
          {props.formText && <FormText>{props.formText}</FormText>}
          {this.state._validation[props.name] && <FormFeedback className="d-block">{this.state._validation[props.name]}</FormFeedback>}
        </Col>
      </FormGroup>
    );
  };

  FormInputTwoLines = (props) => {
    return (
      <FormGroup>
        <Label for={props.name}>{props.label}</Label>
        {this._renderInput(props)}
        {props.children}
        {props.formText && <FormText>{props.formText}</FormText>}
        {this.state._validation[props.name] && <FormFeedback>{this.state._validation[props.name]}</FormFeedback>}
      </FormGroup>
    );
  };

  ButtonToggle = (props) => {
    const value = !!this.state[props.name];
    return (
      <ButtonGroup>
        <Button color={value ? 'primary' : 'secondary'} outline={!value}
          onClick={() => this.setState({[props.name]: !value})}
        >
          Yes
        </Button>
        <Button color={!value ? 'primary' : 'secondary'} outline={value}
          onClick={() => this.setState({[props.name]: !value})}
        >
          No
        </Button>
      </ButtonGroup>
    );
  }
}
