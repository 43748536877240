import React from 'react';
import { Button, Col, Row, Spinner, Container,
  Nav, NavItem, NavLink } from 'reactstrap';
import { Circle as IconDisabled, CheckCircleFill as IconEnabled } from 'react-bootstrap-icons';

import api from '../API';
import { getCurrentRegionId } from '../RegionPicker';


const Icon = props => {
  const Tag = props.following ? IconEnabled : IconDisabled;
  const passProps = {...props};
  delete passProps.following;
  return (<Tag {...passProps}/>);
};


class Seller extends React.Component {
  state = {};

  onClick = async () => {
    if (this._loading) {
      return; // prevent double clicks
    }

    this.setState({_loading: true});
    if (this.props.subscription) {
      // unfollow
      await api.del(`subscriptions/${this.props.subscription.id}`);
    }
    else {
      // follow
      const details = {
        seller: this.props.id,
        restrictProduct: false,
      };

      await api.post(`subscriptions`, details);
    }
    this.props.onUpdate();
  }

  componentDidUpdate(prevProps) {
    if (this.state._loading && prevProps.subscription !== this.props.subscription) {
      // component updated after follow/unfollow action
      this.setState({_loading: false});
    }
  }

  render() {
    const props = this.props;
    return (
      <Col xl={3} lg={4} md={6} className="pb-3">
        <div
          onClick={!props.disabled ? this.onClick : null}
          className={`px-2 py-2 border border-success rounded d-block text-truncate w-100 text-left ${props.disabled ? 'text-secondary' : 'text-success pointer'}`}
        >
          {this.state._loading ?
            <Spinner color="primary" size="sm" className="mr-2"/>
          :
            <Icon following={props.following} className="mr-2"/>
          }
          {props.name}
        </div>
      </Col>
    );
  }
}


export default class SubscriptionList extends React.Component {
  state = {
    _loading: true,
    activeRegion: getCurrentRegionId(),
  };

  async loadAll(blocking=true) {
    this.setState({_loading: blocking});
    const regionsCall = api.get('region');
    const subscriptions = await api.get('subscriptions');
    const regions = await regionsCall;

    // mark regions that are being followed
    subscriptions.forEach(s => {
      if (s.seller) {
        const region = regions.find(r => r.id === s.seller_region);
        region.followSeller = true; // at least one fisher is being followed
      }
      else {
        // following the whole region
        const region = regions.find(r => r.id === s.region);
        region.subscription = s;
      }
    });

    this.setState({
      regions: regions,
      subscriptions: subscriptions,
    });

    this.changeRegion(this.state.activeRegion, blocking);
  }

  async componentDidMount() {
    await this.loadAll();
  }

  async changeRegion(regionId, blocking=true) {
    if (!regionId) {
      // open the first tab if no region selected
      regionId = this.state.regions[0].id;
    }
    this.setState({
      activeRegion: regionId,
      activeRegionDetails: this.state.regions.find(e => e.id === regionId),
      _loading: blocking,
    });

    const sellers = await api.get(`seller`, {region_id: regionId});

    sellers.forEach(e => {
      const subscription = this.state.subscriptions.find(s => s.seller === e.id);
      if (subscription) {
        e.subscription = subscription;
      }
    });

    this.setState({
      sellers: sellers,
      _loading: false,
    });
  }

  deleteAll = async () => {
    await api.callAPI(this, api.del(`subscriptions/delete_all`), false);
    this.loadAll();
  }

  followRegion = async () => {
    this.setState({_loading: true});
    const subscription = this.state.activeRegionDetails.subscription;

    if (subscription) {
      // unfollow
      await api.del(`subscriptions/${subscription.id}`);
    }
    else {
      // follow
      const details = {
        restrictProduct: false,
        region: this.state.activeRegion
      };

      await api.post(`subscriptions`, details);
    }
    this.loadAll();
  }

  render() {
    const followingRegion = this.state.activeRegionDetails && !!this.state.activeRegionDetails.subscription;

    return (
      <div className="text-left">
        <Nav tabs className="border-primary">
          {!this.state.regions ? <Spinner color="primary"/> : this.state.regions.map(e => (
            <NavItem key={e.id}>
              <NavLink
                href="#"
                active={this.state.activeRegion === e.id}
                onClick={() => this.changeRegion(e.id)}
                className={"border-bottom-0 " + ((this.state.activeRegion === e.id) ? "border-primary bg-white text-primary" : "")}
              >
                {e.name}
                {(e.subscription || e.followSeller) && <Icon following className="ml-2 text-success"/>}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        <Container className="p-2 border border-top-0 border-primary rounded-bottom bg-light">
          {this.state._loading ? <Spinner color="primary"/> :
            (this.state.activeRegionDetails && <>
              <Row className="mb-3">
                <Col className="text-left" md={6} lg={4}>
                  <div
                    onClick={this.followRegion}
                    className="pointer px-2 py-2 border border-success rounded text-success d-block text-truncate w-100 text-left"
                  >
                    <Icon className="mr-2" following={followingRegion}/>
                    {`All in ${this.state.activeRegionDetails.name}`}
                  </div>
                </Col>
                <Col className="text-left" md={6} lg={8}>
                  {this.state.activeRegionDetails.subscription ?
                    <p>Email notifications are active for all fishers in {this.state.activeRegionDetails.name}.</p>
                  :
                    <p>You can enable email notifications for all fishers in {this.state.activeRegionDetails.name} with a single click.</p>
                  }
                </Col>
              </Row>

              {followingRegion ?
                <Row className="washout-disabled">
                  {(this.state.sellers || []).map(e => (
                    <Seller
                      key={e.id}
                      {...e}
                      following
                      disabled
                    />
                  ))}
                </Row>
              :
                <>
                  <Row>
                    <Col>
                      <h6 className="text-left">Or you can pick individual fishers in your area.</h6>
                    </Col>
                  </Row>
                  <Row>
                    {(this.state.sellers || []).map(e => (
                      <Seller
                        key={e.id}
                        {...e}
                        following={!!e.subscription}
                        onUpdate={() => this.loadAll(false)}
                      />
                    ))}
                  </Row>
                </>
              }
            </>)
          }
        </Container>
        <div className="text-left">
          <p>
            <Icon className="mr-2 text-success" following/> means that you'll receive email notification from the fisher/region.
            <br/>
            <Icon className="mr-2 text-success"/> means that email notification from this fisher/region is disabled.
          </p>
        </div>
        <div className="text-left p-2 mt-3 border border-primary rounded">
          <p className="mb-0">
            <Button color="danger" className="mr-2" onClick={this.deleteAll}>Disable all</Button>
            Getting too many email notifications? You can disable all of them with a single click.
          </p>
        </div>

      </div>
    );
  }
}
