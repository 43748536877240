import { toast } from 'react-toastify';
import * as moment from 'moment';

const VERSION = 2;

var cartData = {
  items: [],
  version: VERSION,
};

class Cart {
  constructor() {
    const cart = JSON.parse(localStorage.getItem("cart")) || {};
    if (cart.version === VERSION) {
      cartData = cart;
    }
    this._updateCart();
  }

  _storeCart() {
    localStorage.setItem("cart", JSON.stringify(cartData));
  }

  _updateCart() {
    // filter old stuff
    const today = moment().format('YYYY-MM-DD');
    cartData.items = cartData.items.filter(e => e.availableDate >= today);

    // notify listeners
    this._callback && this._callback(this.getCount());

    // store changes
    this._storeCart();
  }

  _updateQty(item, quantity) {
    item.quantity = quantity;
    item.totalPriceGstExcl = Math.round(100.0 * (item.state.priceGstExcl * item.quantity)) / 100.0;
    item.totalPriceGstIncl = Math.round(100.0 * (item.state.priceGstIncl * item.quantity)) / 100.0;
    this._updateCart();
  }

  addToCart(stock, state, quantity) {
    var cartItem = cartData.items.find(e => ((e.stockId === stock.id) && (e.stateId === state.id)));
    if (cartItem) {
      this._updateQty(cartItem, cartItem.quantity + quantity);
    }
    else {
      cartItem = {
        stockId: stock.id,
        stateId: state.id,
        seller: stock.seller,
        seller_name: stock.seller_details.name,
        product: stock.product_details,
        state: state,
        stock: stock,
        availableDate: stock.availableDate,
        quantityAvailable: stock.quantityAvailable, // TODO update from the server at some point?
      };
      cartData.items.push(cartItem);
      this._updateQty(cartItem, quantity);
    }
    this._updateCart();

    toast.success(`${stock.product_details.name} added to cart.`, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
    });
  }

  updateQty(index, quantity) {
    this._updateQty(cartData.items[index], quantity);
    this._updateCart();
  }

  remove(index) {
    const item = cartData.items[index];

    cartData.items.splice(index, 1);
    this._updateCart();

    toast.success(`${item.product.name} removed from cart.`, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
    });
  }

  getCartDataGrouped() {
    const groups = {};

    cartData.items.forEach((e, i) => {
      const key = `${e.seller_name}_${e.availableDate}`;
      const group = groups[key] || {
        seller_name: e.seller_name,
        seller: e.seller,
        availableDate: e.availableDate,
        items: [],
        totalGstIncl: 0,
        totalGstExcl: 0,
      };

      group.items.push({
        entry: e,
        index: i, // original index in the cart array
      });
      group.totalGstIncl += e.totalPriceGstIncl;
      group.totalGstExcl += e.totalPriceGstExcl;

      groups[key] = group;
    });
    return Object.values(groups);
  }

  removeOrdered(items) {
    items.forEach(item => {
      var index = cartData.items.findIndex(e => ((e.stockId === item.entry.stockId) && (e.stateId === item.entry.stateId)));
      cartData.items.splice(index, 1);
    })
    this._updateCart();
  }

  getCount() {
    return cartData.items.length;
  }

  subscribe(callback) {
    this._callback = callback;
  }

  isCartValid() {
    return this.getCount() > 0;
  }

  empty() {
    cartData.items = [];
    this._updateCart();
  }
}

export default new Cart();
