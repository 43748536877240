import React, { useState } from 'react';
import logo from './logo.png';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, Badge, Container, NavbarToggler, Collapse,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { BrowserRouter as Router, Route, Redirect, Link, useHistory, NavLink as RRNavLink } from 'react-router-dom';
import { TiUserOutline as UserIcon, TiShoppingCart as CartIcon } from 'react-icons/ti';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import * as moment from 'moment';
import 'moment/locale/en-nz';

import './App.scss';

import List from './Catalog/List';
import Detail from './Catalog/Detail';
import SellerProfile from './Catalog/SellerProfile';
import Checkout from './Cart/Checkout';
import RegionPicker from './RegionPicker';

import Login from './User/Login';
import Logout from './User/Logout';
import Register from './User/Register';
import PasswordReset from './User/PasswordReset';
import PasswordResetConfirm from './User/PasswordResetConfirm';
import UserSettings from './User/Settings';

import OrdersList from './Customer/OrdersList';
import OrderDetail from './Customer/OrderDetail';
import Favourites from './Customer/Favourites';

import SellerList from './Seller/List';
import SellerProducts from './Seller/ListProducts';
import SellerRegister from './Seller/Register';
import SellerSettings from './Seller/Settings';
import SellerOrdersList from './Seller/OrdersList';
import SellerOrderDetail from './Seller/OrderDetail';
import SellerReports from './Seller/Reports';

import AdminSettings from './Admin/Settings';
import SellerDropdown from './Admin/SellerDropdown';

import auth from './User/Auth';
import cartStorage from './Cart/Cart';

// yeah, stupid moment defaults to US and there's no good way to use browser locale. WHY?! It's 2020!
moment.locale('en-nz');

const withParams = (Component) => {
  // handy wrapper to handle url parameters
  return (props) => (<Component {...props.match.params} {...props}/>);
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    auth.isAuthenticated()
      ? <Component {...props} />
      : <Redirect to={{
          pathname: "/login",
          state: {
            prevLocation: props.location,
          },
        }}/>
  )} />
)

const SellerRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    auth.isSeller()
      ? <Component {...props} />
      : <Redirect to={{
          pathname: "/login",
          state: {
            prevLocation: props.location,
          },
        }}/>
  )} />
)

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    auth.isAdmin()
      ? <Component {...props} />
      : <Redirect to={{
          pathname: "/login",
          state: {
            prevLocation: props.location,
          },
        }}/>
  )} />
)

function Menu() {
  useHistory(); // this will force update on route change (important after login/logout)

  const [cartCount, updateCartCount] = useState(cartStorage.getCount());
  cartStorage.subscribe(updateCartCount);

  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  // I can be a normal seller. I can be an admin without seller's account selected. That affects the menu.
  const [showSellerMenu, setShowSellerMenu] = useState(auth.isSeller() && !auth.isAdmin());

  const showMenu = auth.isAdmin() || auth.isSeller();

  return (
    <Navbar fixed="top" expand="md" light>
      <NavbarBrand tag={Link} to="/" className="logo pb-0 pt-0">
        <img src={logo} width="104" height="45" className="d-inline-block align-top" alt="Home"/>
      </NavbarBrand>
      <Nav className="mr-auto" navbar>
        <NavItem>
          <RegionPicker/>
        </NavItem>
        {auth.isAdmin() &&
          <NavItem>
            <SellerDropdown onSellerRead={setShowSellerMenu}/>
          </NavItem>
        }
        {showMenu &&
          <Collapse isOpen={!collapsed} navbar>
          {auth.isAdmin() &&
            <React.Fragment>
              <NavItem>
                <NavLink href={`${process.env.REACT_APP_BACKEND_URL}/admin`} target="_blank">Admin</NavLink>
              </NavItem>
              {!showSellerMenu &&
                <NavItem>
                  <NavLink tag={RRNavLink} to="/admin/settings">Users</NavLink>
                </NavItem>
              }
            </React.Fragment>
          }
          {((auth.isSeller() && !auth.isAdmin()) || showSellerMenu) &&
            <React.Fragment>
              <NavItem>
                <NavLink tag={RRNavLink} to="/seller/list">Listings</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={RRNavLink} to="/seller/products">Products</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={RRNavLink} to="/seller/orders">Orders</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={RRNavLink} to="/seller/reports">Reports</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={RRNavLink} to="/seller/settings">Settings</NavLink>
              </NavItem>
            </React.Fragment>
          }
        </Collapse>
      }
      </Nav>
      {showMenu &&
        <NavbarToggler onClick={toggleNavbar} className="mr-2"/>
      }

      <div className="text-nowrap">
        <Link to="/checkout" className="mr-2 text-nowrap">
          <CartIcon color="white" size="2em"/>
          <Badge color="primary">{cartCount}</Badge>
        </Link>
        {auth.isAuthenticated() ?
          <UncontrolledDropdown inNavbar className="d-inline-block">
            <DropdownToggle nav caret className="ml-2 p-0">
              <UserIcon color="white" size="2em"/>
              <span style={{maxWidth: '5em'}} className="d-inline-block text-truncate align-middle">{auth.getUsersShortName()}</span>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header>
                {auth.getUserDetails().name}
                <br/>
                {auth.getUserDetails().email}
              </DropdownItem>
              <DropdownItem tag={Link} to="/settings">
                My Details
              </DropdownItem>
              <DropdownItem tag={Link} to="/orders">
                My Orders
              </DropdownItem>
              <DropdownItem tag={Link} to="/favourites">
                Notifications
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag={Link} to="/logout">
                Sign Out
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        :
          <Link to="/login" className="mx-2 text-nowrap">
            <UserIcon color="white" size="2em"/>
            {"Sign In"}
          </Link>
        }
      </div>
    </Navbar>
  );
}


function App() {
  return (
    <Router>
      <div className="App">
        <Menu/>

        <ToastContainer newestOnTop/>

        <Container>
          <Route exact path="/" component={List} />
          <Route exact path="/seller-profile/:sellerId" component={withParams(SellerProfile)} />
          <Route exact path="/detail/:id" component={withParams(Detail)} />
          <Route exact path="/checkout" component={Checkout} />

          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/resetPassword" component={PasswordReset} />
          <Route exact path="/resetPasswordConfirm/:uid/:token/" component={withParams(PasswordResetConfirm)} />

          <PrivateRoute exact path="/orders" component={OrdersList} />
          <PrivateRoute exact path="/orders/:id" component={withParams(OrderDetail)} />
          <PrivateRoute exact path="/favourites" component={Favourites} />
          <PrivateRoute exact path="/settings" component={UserSettings} />

          <SellerRoute exact path="/seller/settings" component={SellerSettings} />
          <SellerRoute exact path="/seller/list" component={SellerList} />
          <SellerRoute exact path="/seller/products" component={SellerProducts} />
          <SellerRoute exact path="/seller/orders" component={SellerOrdersList}/>
          <SellerRoute exact path="/seller/orders/:id" component={withParams(SellerOrderDetail)}/>
          <SellerRoute exact path="/seller/reports" component={SellerReports}/>
          <Route exact path="/seller/register/:emailb64/:token/:sellerId" component={withParams(SellerRegister)} />
          <Route exact path="/seller/register/:emailb64/:token/" component={withParams(SellerRegister)} />

          <AdminRoute exact path="/admin/settings" component={AdminSettings} />
        </Container>

        <footer className="mt-3 py-5">
          <div className="container">
            <p className="m-0 text-center text-white">Copyright &copy; eCatch 2020</p>
          </div>
        </footer>

      </div>
    </Router>
  );
}

export default App;
