import React from 'react';
import { Col, Row, Button, ListGroup, ListGroupItem, Collapse,
  Spinner } from 'reactstrap';
import * as moment from 'moment';
import { Link } from 'react-router-dom';

import Item from './Item';
import PastItem from './PastItem';
import api from '../API';
import { getCurrentRegionId } from '../RegionPicker';


class SellersList extends React.Component {
  state = {
    sellers: [],
    expanded: window.innerWidth >= 992, // expand by default only on large screens
  };

  async componentDidMount() {
    const sellers = await api.get(`seller`, {region_id: getCurrentRegionId()});
    this.setState({
      sellers: sellers,
    });
  }

  render() {
    return (
      <div className="mb-3">
        <Button block
          color="primary"
          size="lg"
          className="dropdown-toggle dropright"
          onClick={() => this.setState({expanded: !this.state.expanded})}
        >
          Sellers
        </Button>

        <Collapse isOpen={this.state.expanded}>
          <ListGroup>
            {this.state.sellers.map(e => (
              <ListGroupItem key={e.id} tag={Link} to={`/seller-profile/${e.id}`} action className="text-primary">{e.name}</ListGroupItem>
            ))}
          </ListGroup>
        </Collapse>
      </div>
    );
  }
}

export default class CatalogList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      _loading: true,
      stock: [],
    };

    this.loadCatalog = this.loadCatalog.bind(this);
  }

  async loadCatalog() {
    this.setState({_loading: true});

    const stock = await api.callAPI(this, api.get('stock', {
      seller__region_id: getCurrentRegionId(),
      seller_id: this.state.seller_id,
      product__template_id: this.state.product__template_id,
      availableDate__gte: moment().format("YYYY-MM-DD"),
      availableDate: this.state.availableDate,
    }), false);
    this.setState({_loading: false, stock: stock});
  }

  async loadPastSales() {
    const stock = await api.callAPI(this, api.get('stock', {
      seller__region_id: getCurrentRegionId(),
      availableDate__gte: moment().subtract(7, 'days').format("YYYY-MM-DD"),
      availableDate__lt: moment().format("YYYY-MM-DD"),
      ordering: '-availableDate',
    }), false);
    this.setState({pastSales: stock});
  }

  async componentDidMount() {
    this.loadCatalog();
    this.loadPastSales();
  }

  render() {
    return (
      <Row>

        <Col lg={3}>
          <SellersList/>
        </Col>

        <Col lg={9}>
          {this.state._loading ? <Spinner color="primary"/> :
            <React.Fragment>
              <Row>
                {this.state.stock.map(e => (
                  <Col key={e.id} lg={4} md={6} className="mb-4">
                    <Item {...e}/>
                  </Col>
                ))}
              </Row>
              <Row>
                {!this.state.stock.length ?
                  <Col>
                    <h5 className="text-primary text-left">
                      There's no fish at the moment but don't worry, there will be more soon.
                      You can get notified when new fish arrives and score the freshest catch.
                      <Link to="/favourites">
                        <Button color="info float-right ml-2">Manage Notifications</Button>
                      </Link>
                    </h5>
                  </Col>
                :
                  <Col>
                    <h5 className="text-primary text-left">
                      Didn't find what you were looking for? You can get notified when new fish arrives.
                      <Link to="/favourites">
                        <Button color="info float-right ml-2">Manage Notifications</Button>
                      </Link>
                    </h5>
                  </Col>
                }
              </Row>
            </React.Fragment>
          }
          {this.state.pastSales && this.state.pastSales.length>0 &&
            <Row className="mt-4">
              <Col>
                <h5>Listings from last week</h5>
              </Col>
            </Row>
          }
          <Row>
            {!this.state.pastSales ? <Spinner color="primary"/> :
              this.state.pastSales.map(e => (
                <PastItem key={e.id} {...e}/>
              ))
            }
          </Row>
        </Col>
      </Row>
    );
  }
}
