import React from 'react';
import Linkify from 'react-linkify';


const linkDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noreferrer">
    {text}
  </a>
);

const LinkifyNewTab = props => (
  <Linkify componentDecorator={linkDecorator}>
    {props.children}
  </Linkify>
);


export default LinkifyNewTab;
