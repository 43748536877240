import React from 'react';
import { Button, Spinner, Form, FormText } from 'reactstrap';
import { toast } from 'react-toastify';

import api from '../API';
import FormBase from '../Util/FormBase';


export default class Settings extends FormBase {
  constructor(props) {
    super(props);
    this.state = {
      _loading: true,
      _validation: {},
    };
  }

  async componentDidMount() {
    const userDetails = await api.get(`user`);
    this.setState({
      email: userDetails.email,
      name: userDetails.name,
      shipping_address: userDetails.shippingAddress && userDetails.shippingAddress.address,
      shipping_addressCity: userDetails.shippingAddress && userDetails.shippingAddress.addressCity,

      _isBusiness: !!userDetails.buyer,
      _isBusinessApproved: userDetails.buyer && userDetails.buyer.approved,
      buyerName: userDetails.buyer && userDetails.buyer.name,
      gstNumber: userDetails.buyer && userDetails.buyer.gstNumber,
      phone: userDetails.buyer && userDetails.buyer.phone,
      billing_address: userDetails.buyer && userDetails.buyer.billingAddress && userDetails.buyer.billingAddress.address,
      billing_addressCity: userDetails.buyer && userDetails.buyer.billingAddress && userDetails.buyer.billingAddress.addressCity,

      _loading: false,
    });
  }

  validate() {
    const errors = {};
    this.validateRequired('name', errors);
    if (this.state._isBusiness) {
      this.validateRequired('buyerName', errors);
      this.validateRequired('gstNumber', errors);
      this.validateRequired('phone', errors);
      this.validateRequired('billing_address', errors);
      this.validateRequired('billing_addressCity', errors);
    }
    return errors;
  }

  async onSubmit() {
    const details = {
      name: this.state.name,
      shippingAddress: {
        address: this.state.shipping_address,
        addressCity: this.state.shipping_addressCity,
      },
    };

    if (this.state._isBusiness) {
      details.buyer = {
        name: this.state.buyerName,
        phone: this.state.phone,
        gstNumber: this.state.gstNumber,
        billingAddress: {
          address: this.state.billing_address,
          addressCity: this.state.billing_addressCity,
        },
      };
    }

    try {
      await api.callAPI(this, api.put(`user`, details), false, '_blocking');
      toast.success('Details updated.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
    catch(err) {
      // already handled. Just don't propagate.
    }
  }

  render() {
    if (this.state._loading) {
      return <Spinner color="primary"/>;
    }
    const FormInput = this.FormInput;

    return (
      <Form onSubmit={this.handleSubmit.bind(this)}>
        <FormInput type="text" name="name" label="Your Name"
          formText="This is how we will call you."
          placeholder="e.g. Homer Simpson"/>

        <FormInput type="email" name="email" label="Email" disabled={true}
          placeholder="e.g. homer@simpson.com"/>

        {this.state._isBusiness &&
          <React.Fragment>
            <FormInput type="text" name="buyerName" label="Business Name"
              placeholder="e.g. FishPlate Dunedin"/>
            <FormInput type="text" name="gstNumber" label="GST Number"
              placeholder="e.g. 123-456-789"/>

            <FormInput type="text" name="phone" label="Phone Number"
              formText="In case we need to contact you."
              placeholder="e.g. 0210123456"/>

            <FormInput type="text" name="billing_address"
              label="Billing Address"
            />
            <FormInput type="text" name="billing_addressCity"
              label="City"
            />
          </React.Fragment>
        }
        <FormInput type="text" name="shipping_address"
          label="Shipping/Delivery Address"
        />
        <FormInput type="text" name="shipping_addressCity"
          label="City"
        />
        {!this.state._isBusiness &&
          <FormInput type="custom" label="Business Customer">
            <Button onClick={() => this.setState({_isBusiness: true})}>Become a business customer</Button>
            <FormText>{"Choose this if you're a restaurant or similar business and want to access wholesale prices."}</FormText>
          </FormInput>
        }

        {this.state._isBusiness && !this.state._isBusinessApproved &&
          <FormText color="danger" className="mb-3">{"Note: Your business account is waiting to be approved. Once approved, you'll be able to see wholesale prices."}</FormText>
        }
        {this.state._blocking ? <Spinner color="primary"/> : <Button color="success">Update</Button>}

      </Form>
    );
  }
}
