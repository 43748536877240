import React from 'react';
import { Button, Form, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import auth from './Auth.js';
import FormBase from '../Util/FormBase';
import api from '../API';
import { ValidationException } from '../Util/Util';
import RegionPicker, {getCurrentRegionId} from '../RegionPicker';


export default class Register extends FormBase {
  API_ENDPOINT = 'user';
  REDIRECT = '/';

  constructor(props) {
    super(props);
    this.state = {
      subscribe: true,
      region_id: getCurrentRegionId(),
      _validation: {}
    };
  }

  validateUser(errors) {
    this.validateEmail('email', errors);
    this.validatePasswords(errors);
  }

  validate() {
    const errors = {}
    this.validateUser(errors);
    this.setState({_validation: errors});

    return errors;
  }

  async onSubmit() {
    this.setState({_blocking: true});
    var history = this.props.history;
    var loginData = this.reactStateToDoc();
    try {
      await api.post(this.API_ENDPOINT, loginData);
      loginData = {
        username: this.state.email,
        password: this.state.password,
      };
      await auth.login(loginData);
      const prevLocation = this.props.location && this.props.location.state && this.props.location.state.prevLocation;
      history.replace(prevLocation || this.REDIRECT);

      toast.success('User registered successfully. You are logged in.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
    catch(err) {
      if (err instanceof ValidationException) {
        this.setState({_validation: {
          ...err.data,
        }});
      }
      else {
        toast.error('Something went wrong: ' + err, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    }
    this.setState({_blocking: false});
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit.bind(this)}>
        <this.FormInput type="email" name="email" label="Email"/>
        <this.FormInput name="password" label="Password" type="password">
          <this.PasswordStrengthBar/>
        </this.FormInput>
        <this.FormInput name="passwordRepeat" label="Password (repeat)" type="password"/>
        <this.FormInput name="subscribe" type="checkbox">
          <p>
            Get notified every time fresh fish appears in
            <RegionPicker onSelect={(regionId) => this.setState({region_id: regionId})}/>
          </p>
        </this.FormInput>

        {this.state._blocking ? <Spinner color="primary" /> : <Button color="success">Register</Button>}
      </Form>
    );
  }
}
