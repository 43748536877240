import React from 'react';
import { Button, Spinner, Table, FormText } from 'reactstrap';
import * as moment from 'moment';

import api from '../API';
import { formatCost, GST, GST_ONLY } from '../Util/Util';


export default class SummaryReport extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      _loading: true,
      rows: [],
      month: null,
    };
  }

  async loadList() {
    this.setState({_loading: true});
    const params = {};
    if (this.state.month) {
      params.month = this.state.month;
    }
    const rows = await api.callAPI(this, api.get('seller/financialSummary', params), false)
    this.setState({_loading: false, rows: rows});
  }

  componentDidMount() {
    this.loadList();
  }

  setMonth(date) {
    this.setState({month: date}, this.loadList.bind(this));
  }

  render() {
    return (
      <React.Fragment>
        {this.state._loading ? <Spinner color="primary"/> :
          <>
            <Table hover>
              <thead className="thead-dark">
                <tr>
                  <th className="text-right">Date</th>
                  <th className="text-left">Item</th>
                  <th className="text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                {this.state.rows.map((row, i) => {
                  const platformFee = row.platformFee || 0;
                  const platformFeeGst = platformFee / GST * (GST_ONLY);
                  const totalChargedGstIncl = row.totalChargedGstIncl || 0;
                  const totalGst = totalChargedGstIncl - (row.totalChargedGstExcl || 0);

                  return (
                    <React.Fragment key={i}>
                      <tr className="table-secondary">
                        <td className="text-right font-weight-bold">
                          {this.state.month ?
                              moment(row.date).format("D MMM YYYY")
                            :
                              <Button color="link" className="p-0" onClick={() => this.setMonth(row.date)}>{moment(row.date).format("MMM YYYY")}</Button>
                          }
                        </td>
                        <td className="text-left">
                          Total Sales
                          <br/>
                          <small class="text-muted">GST Inclusive</small>
                        </td>
                        <td className="text-right">{formatCost(totalChargedGstIncl)}</td>
                      </tr>
                      <tr className="table-secondary">
                        <td></td>
                        <td className="text-left">
                          Fish Local Fee
                          <br/>
                          <small class="text-muted">Includes GST of (you can claim this back)</small>
                        </td>
                        <td className="text-right">
                          -{formatCost(platformFee)}
                          <br/>
                          <small class="text-muted">{formatCost(platformFeeGst)}</small>
                        </td>
                      </tr>
                      <tr className="table-secondary">
                        <td></td>
                        <td className="text-left font-weight-bold">
                          Payout
                          <br/>
                          <small class="text-muted">Payment transferred to your bank account, includes GST of</small>
                        </td>
                        <td className="text-right font-weight-bold">
                          {formatCost(totalChargedGstIncl - platformFee)}
                          <br/>
                          <small class="text-muted">{formatCost(totalGst - platformFeeGst)}</small>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>

            <FormText color="danger" className="mb-3">{"Note: Financial transactions displayed as of yesterday. Fish sold today may not appear here until tomorrow."}</FormText>

            {this.state.month && <Button className="mb-1" color="primary" onClick={() => this.setMonth(null)}>Back to Summary</Button>}
          </>
        }
      </React.Fragment>
    );
  }
}
