import React from 'react';
import { Col, Row, Spinner, Container, Table,
  Card, CardBody } from 'reactstrap';
import * as moment from 'moment';

import api from '../API';
import { formatCost } from '../Util/Util';
import { getOrderStatus } from '../Util/OrderStatus';

const Field = (props) => {
  return (
    <Row>
      <Col xs={4} className="text-right">
        <label>{props.label}</label>
      </Col>
      <Col xs={8} className="text-left">
        <span>{props.value}</span>
      </Col>
    </Row>
  );
}

export default class OrderDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {_loading: true};
  }

  componentDidMount() {
    api.callAPI(this, api.get(`${this.urlPrefix || ''}order/${this.props.id}`));
  }

  render() {
    if (this.state._loading) {
      return (<Spinner color="primary"/>);
    }

    if (!this.state.id) {
      // failed to load order. Don't fail and allow the error popup to do its job.
      return null;
    }

    const shipping = this.state.data.shipping || {};
    const orderStatus = getOrderStatus(this.state.status);
    const totalGstIncl = this.state.data.cart[orderStatus.totalPriceGstInclField];
    const totalGstExcl = this.state.data.cart[orderStatus.totalPriceGstExclField];
    const gst = totalGstIncl - totalGstExcl;

    return (
      <Card>
        <CardBody>
          <Container className="mb-3">
            <Row>
              <Col className="text-left">
                <span>
                  {shipping.name}
                  <br/>
                  {shipping.address}
                  <br/>
                  {shipping.addressCity}
                </span>
              </Col>
              <Col className="text-left">
                <span>
                  {this.state.seller_name}
                  <br/> {(((this.state.data.shipping || {}).seller || {}).pickup || {}).pickupAddress}
                  <br/> GST#: {this.state.seller_gstNumber}
                  {this.state.seller_permitNumber && (
                    <><br/> Permit#: {this.state.seller_permitNumber}</>
                  )}
                  {this.state.data.vessel && (
                    <><br/> Vessel: {this.state.data.vessel}</>
                  )}
                </span>
              </Col>
            </Row>
          </Container>
          <Container>
            <Field label="Order Number" value={this.state.id}/>
            <Field label="Order Placed" value={moment(this.state.created).format('l LT')}/>
            <Field label="Delivery Date" value={moment(this.state.dispatchDate).format('l')}/>
            <Field label="Status" value={orderStatus.label}/>
          </Container>
          <hr/>
          <Table bordered>
            <tbody>
              {this.state.data.cart.items.map((e, i) => (
                <tr key={i}>
                  <td className="text-left">
                    {`${e.product.name}, ${e.state.name}`}
                  </td>
                  <td className="text-right">
                    {`${e[orderStatus.itemQuantityField]} x ${formatCost(this.state.customer_isBusiness ? e.state.priceGstExcl : e.state.priceGstIncl)}/kg`}
                  </td>
                  <td className="text-right">
                    {formatCost(this.state.customer_isBusiness ? e[orderStatus.itemPriceGstExclField] : e[orderStatus.itemPriceGstInclField])}
                  </td>
                </tr>
              ))}

              {this.state.data.shipping.deliveryOption === 'pickup' ?
                <tr>
                  <td colSpan="2" className="text-left">
                    {`Pick Up`}
                  </td>
                  <td className="text-right">
                    {formatCost(0)}
                  </td>
                </tr>
              :
                <tr>
                  <td colSpan="2" className="text-left">
                    {`Delivery: `}{this.state.data.shipping.deliveryDetails.name}
                  </td>
                  <td className="text-right">
                    {formatCost(this.state.customer_isBusiness ? this.state.data.shipping.deliveryDetails.costGstExcl : this.state.data.shipping.deliveryDetails.costGstIncl)}
                  </td>
                </tr>
              }

              {this.state.customer_isBusiness &&
                <tr>
                  <td colSpan="2" className="text-left">
                    <strong>{`Total (Excl. GST)`}</strong>
                  </td>
                  <td className="text-right">
                    <strong>{formatCost(totalGstExcl)}</strong>
                  </td>
                </tr>
              }
              {gst &&
                <tr>
                  <td colSpan="2" className="text-left">
                    {this.state.customer_isBusiness ? 'GST' : 'GST Included'}
                  </td>
                  <td className="text-right">
                    {formatCost(gst)}
                  </td>
                </tr>
              }
              <tr>
                <td colSpan="2" className="text-left">
                  <strong>{`Total `} {this.state.wholesaleCustomer && "(Incl. GST)"}</strong>
                </td>
                <td className="text-right">
                  <strong>{formatCost(totalGstIncl)}</strong>
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}
