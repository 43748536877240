import React from 'react';
import { Container, Col, Row,
  Card, CardHeader, CardImg, CardBody, CardText,
  Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';

import api from '../API';
import { formatDateAvailable } from './Util';
import BuyButton from './BuyButton';
import {ShippingInfo} from './SellerProfile';


export default class CatalogDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      _loading: true
    };
  }

  componentDidMount() {
    api.callAPI(this, api.get(`stock/${this.props.id}`));
  }

  render() {
    if (this.state._loading) {
      return (<Spinner color="primary"/>);
    }
    if (!this.state.id) {
      return null; // API call failed, don't fail the whole screen and allow error to be displayed
    }

    return (
      <Container>
        <Row>
          <Col lg={6}>
            <Card outline color="secondary" className="mb-1">
              <CardHeader>
                {this.state.product_details.name}
              </CardHeader>
              <CardImg top className="img-fluid" src={`${process.env.REACT_APP_BACKEND_URL}${this.state.product_details.imageURI}`} alt=""/>
              <CardBody>
                <CardText className="text-left nl2br">{this.state.product_details.description}</CardText>
                <CardText className="text-left nl2br">{this.state.product_details.notes}</CardText>
                <CardText className="text-left nl2br">{this.state.notes}</CardText>

                <CardText className="text-left">
                  {`Pickup/Delivery: `}<b>{`${formatDateAvailable(this.state.availableDate)}`}</b>
                </CardText>

                <BuyButton {...this.state} states={this.state.states || []}/>
              </CardBody>
            </Card>
          </Col>

          <Col lg={6}>
            <Card outline color="secondary" className="mb-1">
              <CardHeader>
                Sold by <Link to={`/seller-profile/${this.state.seller}`}>{this.state.seller_details.name}</Link>
              </CardHeader>
              {this.state.seller_details.publicDescription && <CardBody>
                <CardText className="text-left nl2br">{this.state.seller_details.publicDescription}</CardText>
              </CardBody>}
            </Card>
            <ShippingInfo {...this.state.seller_details}/>
          </Col>
        </Row>
      </Container>
    );
  }
}
