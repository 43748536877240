import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Button, FormText, Container, Row, Col,
  Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

import { ValidationException } from '../Util/Util';
import api from '../API';
import auth from '../User/Auth';
import { formatCost } from '../Util/Util';
import cartData from './Cart';
import Linkify from '../Util/Linkify';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const STRIPE_AUTH_AMOUNT = 1.2;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "16px",
      color: '#495057',
      fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      '::placeholder': {
  		  color: "#868e96",
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

function updateUserPreferences(data) {
  if (auth.isAuthenticated()) {
    api.put(`user`, {
      name: data.name,
      shippingAddress: {
        address: data.address,
        addressCity: data.addressCity,
      },
    });
  }
}

const PaymentElement = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState('');
  const [cardReady, setCardReady] = useState(false);
  const [spinnerModal, setSpinnerModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    // clear error message if I'm coming back after changing cart
    setMessage('');
  }, [props.cart]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (!cardReady) {
      setMessage("Please fill your credit/debit card details.");
      return;
    }

    setSpinnerModal(true);

    try {
      // create a new order on the backend and get client secret from the payment intent
      const cart = {
        ...props.cart,
        // transform to list of cart entries. The cart index is pointelss on the server.
        items: props.cart.items.map(e => e.entry),
      }
      const order = await api.post(`order`, {
        data: {
          cart: cart,
          shipping: props.shipping,
        },
        seller: props.cart.seller,
        dispatchDate: props.cart.availableDate,
      });
      // TODO PUT order if it already exists (user can go back)

      // this can happen asynchronously and we don't care if it fails
      updateUserPreferences(props.shipping);

      const result = await stripe.confirmCardPayment(order.stripeClientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: props.shipping.name,
          },
        }
      });

      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        throw Error(result.error.message);
      }
      else if (result.paymentIntent.status === 'requires_capture') {
        // This is good, this is what we want. Order will be updated via webhook called by Stripe.
      }
      else {
        throw Error(result.paymentIntent.status);
      }

      setSpinnerModal(false);
      setMessage("");
      cartData.removeOrdered(props.cart.items);
      props.onSuccess();
      setSuccessModal(true);
    }
    catch(err) {
      setSpinnerModal(false);
      console.log(err);
      if (err instanceof ValidationException && err.data.non_field_errors) {
        setMessage(err.data.non_field_errors);
      }
      else {
        setMessage(`Something broke, cannot process payment: ${err}`);
      }
    }
  };

  let history = useHistory();

  const totalPrice = ((props.cart && props.cart.totalGstIncl) || 0) +
    ((props.shipping.deliveryDetails && props.shipping.deliveryDetails.costGstIncl) || 0);

  return (
    <div>
      <Modal isOpen={spinnerModal}>
        <ModalHeader>Processing payment...</ModalHeader>
        <ModalBody className="text-center">
          <Spinner/>
        </ModalBody>
      </Modal>

      <Modal isOpen={successModal}>
        <ModalHeader>Success!</ModalHeader>
        <ModalBody>
          Your order has been placed. You will receive a confirmation email.
        </ModalBody>
        <ModalFooter>
          <Button color="primary"
            onClick={() => {
              setSuccessModal(false);
              if (cartData.getCount() === 0) {
                // there's no more fish in this cart
                history.push("/");
              }
            }}
          >Ok</Button>
        </ModalFooter>
      </Modal>

      <Container>
        <Row>
          <Col>
            <h6 className="text-left">{"Total estimated"}</h6>
          </Col>
          <Col xs={5} sm={4} lg={3}>
            <h6 className="text-right" data-testid="totalPrice">
              {formatCost(totalPrice)}
            </h6>
          </Col>
        </Row>
      </Container>
      <FormText color="primary" className="text-left">
        Note: The price is only approximate. It is impossible to predict fish weight accurately. Your card won't be charged at this stage. An authorisation of <strong>{formatCost(totalPrice * STRIPE_AUTH_AMOUNT)}</strong> will be placed on your card and you will be charged the exact amount when your fish is ready.
      </FormText>

      <div className="mt-3">
        <CardElement
          options={CARD_ELEMENT_OPTIONS}
          onReady={props.onReady}
          onChange={evt => {
            setCardReady(evt.complete);
          }}
        />
        <FormText color="danger"><Linkify>{message}</Linkify></FormText>
      </div>

      <FormText color="primary" className="text-left mt-3">
        By placing an order you agree to our <strong><a href="https://fishlocal.nz/fish-local-terms-and-conditions/" target="_blank" rel="noreferrer">Terms and Conditions</a></strong>.
      </FormText>

      <Button
        disabled={!stripe}
        color="primary"
        className="float-right mt-2 mb-2"
        onClick={handleSubmit}
      >
        Place Order
      </Button>
    </div>
  );
}

export default function Payment(props) {
  return (
    <Elements stripe={stripePromise}>
      <PaymentElement {...props}/>
    </Elements>
  );
}
