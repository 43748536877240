import React from 'react';
import { Button, Form, InputGroupAddon } from 'reactstrap';

import SellerSettings from '../Seller/Settings';


export default class Settings extends SellerSettings {
  async componentDidMount() {
    // prevent the seller's API call
  }

  render() {
    return (
      <Form onSubmit={() => {/* there's no submit */}}>
        <this.FormInput type="text" name="inviteUserEmail" label="Invite New Seller" inputGroup
          formText="This is a new user for a new seller company."
          placeholder="e.g. fisher@fishing.nz"
        >
          <InputGroupAddon addonType="append">
            <Button
              color="secondary"
              className="mr-3"
              onClick={() => this.inviteUser('seller/invite')}
              disabled={!this.state.inviteUserEmail || this.state._blocking}
            >
              Invite
            </Button>
          </InputGroupAddon>
        </this.FormInput>
      </Form>
    );
  }
}
