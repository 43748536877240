import React from 'react';
import { Button, Col, Row, Spinner, Container,
  Card, CardImg, CardBody } from 'reactstrap';
import confirm from "reactstrap-confirm";

import api from '../API';
import EditProduct from './EditProduct';
import ProductState from './ProductState';


const Product = (props) => {
  const onDelete = async () => {
    const result = await confirm({
      message: `This will remove ${props.name} from the e-shop. You won't be able to create any ${props.name} listings.  Are you sure you want that?`,
      confirmText: "Yes, remove",
      confirmColor: "danger",
      cancelColor: "primary",
      cancelText: "No, keep it",
    });
    if (result) {
      await api.del(`seller/product/${props.id}`);
      props.onDeleted();
    }
  };

  return (
    <Row className="mb-4 text-left">
      <Col>
        <Card>
          <CardBody>
            <Container>
              <Row>
                <Col xs={12} md={4}>
                  <Card>
                    <CardImg top className="img-fluid" src={`${process.env.REACT_APP_BACKEND_URL}${props.imageURI}`} alt=""/>
                  </Card>
                </Col>
                <Col xs={12} md={4}>
                  <h5 className="text-left">{props.name}</h5>
                  {(props.states || []).map(state => (<ProductState key={state.id} {...state}/>))}
                </Col>
                <Col xs={12} md={4} className="text-right">
                  <Button className="mb-1" onClick={props.onEdit}>Edit</Button><br/>
                  <Button color="danger" onClick={onDelete}>Remove</Button>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}


export default class CatalogList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {_loading: true, products: []};
  }

  async loadList() {
    const products = await api.get('seller/product');
    this.setState({_loading: false, products: products});
  }

  componentDidMount() {
    this.loadList();
  }

  async onEditConfirm(details) {
    this.setState({_editOpen: false});
    this.loadList();
  }

  render() {
    return (
      <Container>
        <EditProduct
          isOpen={this.state._editOpen}
          id={this.state.product_id}
          onConfirm={this.onEditConfirm.bind(this)}
          onClose={() => this.setState({_editOpen: false})}
        />

        <Row className="mb-1">
          <Col className="text-right">
            <Button color="primary"
              onClick={() => this.setState({product_id: null, _editOpen: true})}
            >
              Add New Species
            </Button>
          </Col>
        </Row>
        {this.state._loading ? <Spinner color="primary"/> :
          this.state.products.map(e => (
            <Product key={e.id} {...e}
              onEdit={() => this.setState({product_id: e.id, _editOpen: true})}
              onDeleted={this.loadList.bind(this)}
            />
          ))
        }
      </Container>
    );
  }
}
