import React from 'react';
import { Button, Col, Row, Spinner } from 'reactstrap';

import Item from './Item';
import api from '../API';
import { formatDateAvailable } from '../Catalog/Util';
import EditItem from './EditItem';


export default class CatalogList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {_loading: true, listings: {}};
  }

  async loadList() {
    const stock = await api.callAPI(this, api.get('seller/stock'), false)

    // split listings by date
    const listingsByDate = {};
    stock.forEach(e => {
      listingsByDate[e.availableDate] = listingsByDate[e.availableDate] || [];
      listingsByDate[e.availableDate].push(e);
    });

    this.setState({_loading: false, listings: listingsByDate});
  }

  componentDidMount() {
    this.loadList();
  }

  async onEditConfirm(details) {
    this.setState({_editDetails: null});
    this.loadList();
  }

  render() {
    return (
      <React.Fragment>
        <EditItem
          isOpen={!!this.state._editDetails}
          details={this.state._editDetails}
          onConfirm={this.onEditConfirm.bind(this)}
          onClose={() => this.setState({_editDetails: null})}/>

        <Row className="mb-1">
          <Col className="text-right">
            <Button color="primary"
              onClick={() => this.setState({_editDetails: {}})}
            >
              Add New Listing
            </Button>
          </Col>
        </Row>
        {this.state._loading ? <Spinner color="primary"/> :
          Object.keys(this.state.listings).sort().map(date => (
            <React.Fragment key={date}>
              <Row className="mb-1">
                <Col className="text-left">
                  <h4>Due on {formatDateAvailable(date)}</h4>
                </Col>
              </Row>
              {this.state.listings[date].map(e => (
                <Item key={e.id} {...e}
                  onUpdateQuantity={() => {}}
                  onEdit={() => this.setState({_editDetails: {id: e.id}})}
                  onDeleted={this.loadList.bind(this)}
                />
              ))}
            </React.Fragment>
          ))
        }
      </React.Fragment>
    );
  }
}
