import React from 'react';
import { Button, Form, FormGroup, Label, Input,  Spinner,
  Modal, ModalHeader, ModalBody, ModalFooter, FormFeedback,
  InputGroup, InputGroupAddon } from 'reactstrap';
import * as moment from 'moment';

import api from '../API';
import FormBase, { reactStateToDoc } from '../Util/FormBase';
import EditProduct from './EditProduct';
import {roundQuantity} from '../Util/Util';

const LIMIT_LISTING_DATE_DAYS = 6;

export default class EditItem extends FormBase {
  state = {
    _loading: true,
    _validation: {},
    _mode: 'editItem',
  };

  async onOpen() {
    // clean up previous values
    this.setState({_loading: true, _validation: {}, id: null, product: null, quantityListed: null, _quantitySold: null, availableDate: null, species: null});

    const productsCall = api.get(`seller/product`);
    const speciesCall = api.get(`seller/species`);
    const settingsCall = api.get(`seller/user`);

    if (this.props.details.id) {
      const stockCall = api.get(`seller/stock/${this.props.details.id}`);
      const stock = await stockCall;

      this.setState({
        ...stock,
        quantityListed: roundQuantity(stock.quantityListed),
        _quantitySold: roundQuantity(stock.quantityListed - stock.quantityAvailable),
      });
    }
    else {
      this.setState({
        availableDate: moment().format("YYYY-MM-DD"),
        ...this.props.details,
      });
    }

    const products = await productsCall;
    const species = await speciesCall;
    const settings = await settingsCall;
    this.setState({
      _loading: false,
      _products: products,
      _species: species.map(e => ({id: e.code, name: `${e.code} - ${e.name}`})),
      _vessels: (settings.vessels || []).map(e => ({id: e, name: e})),
    });

  }

  validate() {
    const errors = {};
    this.validateRequired('species', errors);
    this.validateRequired('product', errors);
    this.validateRequired('quantityListed', errors);
    this.validateRequired('availableDate', errors);

    if (this.state.quantityListed && this.state._quantitySold && this.state.quantityListed < this.state._quantitySold) {
        errors['quantityListed'] = `${roundQuantity(this.state._quantitySold)} kg has been sold already. Please enter at least that much.`;
    }

    if (this.state.availableDate &&
        moment(this.state.availableDate).isAfter(moment().add(LIMIT_LISTING_DATE_DAYS, 'days').startOf('day'))) {
      errors.availableDate = `Cannot list fish more than ${LIMIT_LISTING_DATE_DAYS} days in the future.`;
    }
    return errors
  }

  async onSubmit() {
    const details = reactStateToDoc(this.state);

    try {
      if (details.id) {
        await api.callAPI(this, api.put(`seller/stock/${details.id}`, details), false, '_saving');
      }
      else {
        await api.callAPI(this, api.post(`seller/stock`, details), false, '_saving');
      }

      this.props.onConfirm();
    }
    catch(err) {
      // already handled. Just don't propagate.
    }
  }

  render() {
    return (
      <React.Fragment>
        <EditProduct
          isOpen={this.props.isOpen && (this.state._mode === 'editProduct')}
          id={this.state.product}
          onConfirm={() => this.setState({_mode: 'editItem'})}
          onClose={() => this.setState({_mode: 'editItem'})}
        />

        {this.renderForm()}
      </React.Fragment>

    );
  }

  renderForm() {
    const props = this.props;
    return (
      <Modal isOpen={props.isOpen && (this.state._mode === 'editItem')} toggle={props.onClose} onOpened={this.onOpen.bind(this)}>
        <ModalHeader toggle={props.onClose}>Listing Details</ModalHeader>
        <ModalBody>
          {this.state._loading ? <Spinner color="primary"/> :
            <Form>
              <FormGroup>
                <Label for="product">Product</Label>
                <InputGroup>
                  <Input type="select" name="product" id="product"
                    value={this.state.product || ''}
                    invalid={!!this.state._validation.product}
                    onChange={(evt) => {
                      if (evt.target.value === "new") {
                        this.setState({_mode: 'editProduct'});
                      }
                      else {
                        this.setState({product: parseInt(evt.target.value)});
                      }
                    }}
                  >
                    <option>Select...</option>
                    {this.state._products.map(e => (
                      <option key={e.id} value={e.id}>{e.name}</option>
                    ))}
                    <option value="new">{'Add New...'}</option>
                  </Input>
                  {this.state.product &&
                    <InputGroupAddon addonType="append">
                      <Button color="secondary" onClick={() => this.setState({_mode: 'editProduct'})}>Edit</Button>
                    </InputGroupAddon>
                  }
                </InputGroup>
                <FormFeedback>{this.state._validation.product}</FormFeedback>
              </FormGroup>
              <this.FormInputTwoLines type="select" name="species"
                label="Species"
                emptyOption="Select Species..."
                options={this.state._species}
              />
              <this.FormInputTwoLines type="number" inputClassName="kg" name="quantityListed" label="Available Quantity"/>
              {!!this.state._quantitySold && <this.FormInputTwoLines disabled type="number" inputClassName="kg" name="_quantitySold" label="Sold Quantity"/>}
              <this.FormInputTwoLines type="date" name="availableDate" label="Available Date"/>
              <this.FormInputTwoLines type="select" name="vessel"
                label="Vessel"
                emptyOption="No Vessel"
                options={this.state._vessels}
              />
              <this.FormInputTwoLines type="textarea" name="notes" label="Notes (optional)"/>
            </Form>
          }
        </ModalBody>
        <ModalFooter>
          {this.state._saving ? <Spinner color="primary"/> : <Button color="primary" onClick={this.handleSubmit.bind(this)}>Confirm</Button>}
          {' '}
          <Button color="secondary" onClick={props.onClose}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}
