import React from 'react';
import { Collapse, Card, CardHeader, CardBody } from 'reactstrap';

import CartList from './CartList';
import Shipping from './Shipping';
import Payment from './Payment';
import cartData from './Cart';
import api from '../API';

export default class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "cart",
      mainCart: cartData.getCartDataGrouped(),
      cart: [],
      cartValid: false,
      shippingValid: false,
      shipping: {},
      paymentElementRef: null,
    };
  }

  async onCartSubmit(cart, index) {
    const validatedCart = await api.post(`cart/validate`, cart);
    if (validatedCart.isValid) {
      this.setState({
        cart: validatedCart,
        cartValid: true,
        activeTab: "shipping",
      })
    }
    else {
      this.state.mainCart[index] = validatedCart;
      this.setState({mainCart: this.state.mainCart});
    }
  }

  goToPayment = () => {
    if (this.state.cartValid && this.state.shippingValid) {
      this.setState({activeTab: "payment"});
      setTimeout(this.state.paymentElementRef.focus, 500);
    }
  };

  render() {
    return (
      <Card>
        <CardHeader
          className="pointer accordion-heading"
          onClick={() => this.setState({
            cartValid: false,
            activeTab: "cart",
          })}
        >
          Cart
        </CardHeader>
        <Collapse isOpen={this.state.activeTab === "cart"}>
          <CardBody>
            <CartList
              cartData={this.state.mainCart}
              onCartUpdate={cart => this.setState({mainCart: cart})}
              onSubmit={this.onCartSubmit.bind(this)}
            />
          </CardBody>
        </Collapse>

        <CardHeader className="pointer accordion-heading" onClick={() => this.state.cartValid && this.setState({activeTab: "shipping"})}>Shipping</CardHeader>
        <Collapse isOpen={this.state.activeTab === "shipping"}>
          <CardBody>
            <Shipping
              sellerId={this.state.cart.seller}
              onUpdate={(shipping) => this.setState({shipping: shipping})}
              setValid={(shippingValid) => this.setState({shippingValid: shippingValid})}
              onSubmit={this.goToPayment}
            />
          </CardBody>
        </Collapse>

        <CardHeader className="pointer accordion-heading" onClick={this.goToPayment}>Payment</CardHeader>
        <Collapse isOpen={this.state.activeTab === "payment"}>
          <CardBody>
            <Payment
              sellerId={this.state.cart.seller}
              shipping={this.state.shipping}
              cart={this.state.cart}
              onReady={(ref) => this.setState({paymentElementRef: ref})}
              onSuccess={() => {
                // reset everything and show the rest of the cart
                this.setState({
                  cartValid: false,
                  activeTab: "cart",
                  cart: [],
                  mainCart: cartData.getCartDataGrouped(),
                });
              }}
            />
          </CardBody>
        </Collapse>
      </Card>
    );
  }
}
