export class ValidationException extends Error {
  constructor(data) {
    super("Validation Failed");
    this.data = data;
  }
}

export function convertArrayToObject(array, key) {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export function round2(value) {
  const decimals = 2;
  // OMG Javascript rounding is so buggy and hopeless! try rounding 1.035 to 2dp.
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

export function roundQuantity(value) {
  /** Round quntities for saving */
  return round2(value);
}

export function formatQuantity(value) {
  return round2(value).toFixed(2);
}

export function formatCost(val) {
  // toFixed has broken rounding (WTF!) rounding 5 down (1.555 => 1.55 instead of 1.56)
  return `$${round2(val).toFixed(2)}`;
}

export const GST = 1.15; // TODO shouldn't exist on frontend, should be removed when seller can decide if shipping is GST incl or excl.
export const GST_ONLY = 0.15; // TODO shouldn't exist on frontend, should be removed when seller can decide if shipping is GST incl or excl.
