import React from 'react';
import { Card, CardImg, CardBody, CardText, CardFooter, CardTitle, Col, UncontrolledTooltip } from 'reactstrap';

import { formatDateAvailable } from './Util';


export default function PastItem(props) {
  return (
    <Col lg={3} md={4} xs={6} className="mb-4">
      <Card className="washout">
        <CardImg top src={`${process.env.REACT_APP_BACKEND_URL}${props.product_details.imageURI}`} alt=""/>
        <CardBody>
          <CardTitle tag="h6">
            {props.product_details.name}
          </CardTitle>
          <CardText>
            <small>{formatDateAvailable(props.availableDate)}</small>
          </CardText>
        </CardBody>

        {props.showFooter && <CardFooter id={`sellerName_${props.id}`} className="nl2br">
          <small>{props.seller_details.name}</small>
          {props.seller_details.publicDescription && <UncontrolledTooltip placement="top" target={`sellerName_${props.id}`}>
            <div className="text-left nl2br">
              {props.seller_details.publicDescription}
            </div>
          </UncontrolledTooltip>}
        </CardFooter>}
      </Card>
    </Col>
  );
};

PastItem.defaultProps = {
  showFooter: true,
};
