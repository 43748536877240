import React from 'react';
import { Button, Form, FormGroup, Label, Input,  Spinner,
  Modal, ModalHeader, ModalBody, ModalFooter, FormFeedback,
  Col, FormText } from 'reactstrap';

import api from '../API';
import FormBase, { reactStateToDoc } from '../Util/FormBase';


class ProductPricing extends React.Component {
  render() {
    return (
      <FormGroup row>
        <Label for={`price_${this.props.data.id}`} sm={2}>{this.props.data.name}</Label>
        <Col sm={5}>
          <Input type="number" name={`price_${this.props.data.id}`} id={`price_${this.props.data.id}`}
            className="price"
            placeholder="$ per kg"
            value={this.props.data.price || ''}
            onChange={(evt) => {
              this.props.data.price = parseFloat(evt.target.value);
              this.props.onUpdate();
            }}
          />
        </Col>
        <Col sm={5}>
          <Input type="number" name={`priceWholesale_${this.props.data.id}`} id={`priceWholesale_${this.props.data.id}`}
            className="price"
            placeholder="$ per kg"
            value={this.props.data.priceWholesale || ''}
            onChange={(evt) => {
              this.props.data.priceWholesale = parseFloat(evt.target.value);
              this.props.onUpdate();
            }}
          />
        </Col>
        <Col>
          <FormText>{this.props.data.description}</FormText>
        </Col>
      </FormGroup>
    );
  }
}

export default class EditProduct extends FormBase {
  state = {
    _loading: true,
    _validation: {},
  };

  async onOpen() {
    // clean up previous values
    this.setState({
      _loading: true,
      _validation: {},
      name: null,
      template: null,
      notes: null,
      states: [],
      id: null,
    });

    var templateId = null;

    const userCall = api.get('seller/user');
    const templatesCall = api.get(`productTemplate`);
    if (this.props.id) {
      const product = await api.get(`seller/product/${this.props.id}`);
      templateId = product.template;
      this.setState(product);
      const templates = await templatesCall;
      this.onTemplateSelected(templateId, false, templates, product.states);
    }

    const templates = await templatesCall;
    const user = await userCall;
    this.setState({
      _templates: templates,
      _gstRegistered: user.gstRegistered,
      _loading: false,
    });
  }

  validate() {
    const errors = {};
    this.validateRequired('template', errors);
    this.validateRequired('name', errors);
    return errors
  }

  async onSubmit() {
    const details = reactStateToDoc(this.state);

    details.is_retail = this.state.states.some(e => e.price); // at least one retail price defined

    try {
      if (details.id) {
        await api.callAPI(this, api.put(`seller/product/${details.id}`, details), false, '_saving');
      }
      else {
        await api.callAPI(this, api.post(`seller/product`, details), false, '_saving');
      }

      this.props.onConfirm();
    }
    catch(err) {
      // already handled. Just don't propagate.
    }
  }

  onTemplateSelected(templateId, updateName, templates, states) {
    templates = templates || this.state._templates;
    states = states || this.state.states || [];
    const template = templates.find(e => e.id === templateId);

    // get the full list of states from the product template and copy the prices from the currently edited product
    const updatedStates = template.states_details.map(templateState => {
      const state = states.find(state => state.id === templateState.id);
      return {
        ...state, // the product state (=prices)
        ...templateState, // override everything form the template (=state name, ...)
      };
    });

    if (updateName && !this.state.name) {
      this.setState({name: template.name});
    }

    this.setState({
      template: templateId,
      states: updatedStates,
      _templateDetails: template,
    });

  }

  render() {
    const props = this.props;
    return (
      <div>
        <Modal isOpen={props.isOpen} toggle={props.onClose} onOpened={this.onOpen.bind(this)}>
          <ModalHeader toggle={props.onClose}>Product Details</ModalHeader>
          <ModalBody>
            {this.state._loading ? <Spinner color="primary"/> :
              <Form>
                <FormGroup>
                  <Label for="template">Template</Label>
                  <Input type="select" name="template" id="template"
                    value={this.state.template || ''}
                    invalid={!!this.state._validation.template}
                    onChange={evt => this.onTemplateSelected(parseInt(evt.target.value), true)}
                  >
                    <option>Select...</option>
                    {this.state._templates.map(e => (
                      <option key={e.id} value={e.id}>{e.name}</option>
                    ))}
                  </Input>
                  <FormFeedback>{this.state._validation.template}</FormFeedback>
                </FormGroup>

                <p className="form-text text-muted nl2br">{this.state._templateDetails && this.state._templateDetails.description}</p>

                <this.FormInputTwoLines
                  type="text"
                  name="name"
                  label="Name"
                  formText="This will be displayed in the shop"
                />

                <this.FormInputTwoLines
                  type="textarea"
                  name="notes"
                  label="Additional Description (optional)"
                  formText="This will be displayed in the shop in addition to the template description"
                />

                <FormGroup row>
                  <Label sm={2}></Label>
                  <Label sm={5}>Retail price {this.state._gstRegistered && "(GST Incl.)"}</Label>
                  <Label sm={5}>Wholesale price {this.state._gstRegistered && "(GST Excl.)"}</Label>
                </FormGroup>

                {(this.state.states || []).map(e => (
                  <ProductPricing key={e.id} data={e}
                    onUpdate={() => this.setState(this.state.states)}
                  />
                ))}
              </Form>
            }
          </ModalBody>
          <ModalFooter>
            {this.state._saving ? <Spinner color="primary"/> : <Button color="primary" onClick={this.handleSubmit.bind(this)}>Confirm</Button>}
            {' '}
            <Button color="secondary" onClick={props.onClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
