import React from 'react';
import { withRouter } from "react-router-dom";

import api from '../API';
import DropDownFilter from '../Util/Dropdown';


class SellerDropdown extends React.Component {
  state = {
    _loading: true,
    sellers: [],
  };

  async componentDidMount() {
    const userCall = api.get(`user`);
    const sellersCall = api.get(`seller`);
    const user = await userCall;
    const sellers = await sellersCall;

    this.setState({
      sellers: sellers,
      sellerId: user.seller,
    });

    this.props.onSellerRead(user.seller);
  }

  async onChange(sellerId) {
    // update the admin user on the backend
    await api.put(`user`, {seller: sellerId});
    window.location.replace(sellerId ? '/seller/list' : '/admin/settings'); // reload all content
  }

  render() {
    if (!this.props.location.pathname.startsWith("/seller") && !this.props.location.pathname.startsWith("/admin")) {
      // only render for the Seller's interface
      return null;
    }

    return (
      <DropDownFilter
        label="Admin (No Seller)"
        value={this.state.sellerId}
        onChange={this.onChange.bind(this)}
        options={this.state.sellers}
        color="danger"
        emptyLabel="Admin (No Seller)"
      />
    );
  }
}

export default withRouter(SellerDropdown);
