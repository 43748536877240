import React from 'react';
import { Button, Form, Spinner, FormFeedback } from 'reactstrap';
import { toast } from 'react-toastify';

import api from '../API.js';
import FormBase from '../Util/FormBase';


export default class Login extends FormBase {
  constructor(props) {
    super(props);
    this.state = {
      _validation: {}
    };
  }

  componentDidMount() {
    this.setState({uid: this.props.uid, token: this.props.token});
  }

  validate() {
    const errors = {}
    this.validatePasswords(errors);
    return errors;
  }

  async onSubmit() {
    try {
      await api.callAPI(this, api.post('user/resetPasswordConfirm', this.reactStateToDoc()));
      toast.success('Password changed successfully. Please login using your new password.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
      this.props.history.replace('/login');
    }
    catch(err) {
      // nothing to do, toast has been displayed
      console.log(err);
    }
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit.bind(this)}>
        <this.FormInput name="password" label="New Password" type="password">
          <this.PasswordStrengthBar/>
        </this.FormInput>
        <this.FormInput name="passwordRepeat" label="New Password (repeat)" type="password"/>

        {this.state._validation.token && <FormFeedback className="d-block mb-3">{this.state._validation.token}</FormFeedback>}

        {this.state._loading ? <Spinner color="primary"/> : <Button color="success">Confirm</Button>}
      </Form>
    );
  }
}
