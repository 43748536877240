import React from 'react';
import UserRegister from '../User/Register';
import { Button, Form, Spinner, Input, FormFeedback } from 'reactstrap';

import api from '../API';


export default class Register extends UserRegister {
  API_ENDPOINT = 'seller/user';
  REDIRECT = '/seller/settings';

  validate() {
    const errors = {}
    this.validateUser(errors);

    if (!this.props.sellerId) {
      // registering a new seller
      this.validateRequired('sellerName', errors);
      this.validateRequired('phone', errors);
    }

    this.validateRequired('name', errors);

    this.setState({_validation: errors});

    return errors;
  }

  async componentDidMount() {
    this.setState({email: atob(this.props.emailb64), token: this.props.token});
    if (this.props.sellerId) {
      this.setState({sellerId: this.props.sellerId}); // so it gets sent to the server

      const seller = await api.callAPI(this, api.get(`seller/${this.props.sellerId}`), false);
      this.setState({sellerName: seller.name});
    }
    const regions = await api.get(`region`);
    this.setState({
      regions: regions,
      region_id: regions[0].id, // pre-select the first item
    });
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit.bind(this)}>

        {this.props.sellerId ?
          <this.FormInput type="text" name="sellerName" label="Account Name" disabled={true}/>
        :
          <React.Fragment>
            <this.FormInput type="custom" name="region_id" label="Region">
              <Input type="select" name="region_id" id="region_id"
                value={this.state.region_id}
                onChange={this.handleInputChange.bind(this)}
              >
                {(this.state.regions || []).map(e => (
                  <option key={e.id} value={e.id}>{e.name}</option>
                ))}
              </Input>
            </this.FormInput>

            <this.FormInput type="text" name="sellerName" label="Account Name"
              formText="This will be publicly visible to customers and will appear on invoices. Choose a name that represents your business."
              placeholder="e.g. FishHook Dunedin"/>

            <this.FormInput type="text" name="phone" label="Phone Number"
              formText="In case we need to contact you."
              placeholder="e.g. 0210123456"/>
          </React.Fragment>
        }

        <this.FormInput type="text" name="name" label="Your Name"
          formText="This is how we will call you."
          placeholder="e.g. Homer Simpson"/>

        <this.FormInput type="email" name="email" label="Email"
          disabled={true}
          formText="If you want to use a different email address, you'll need a new invitation."/>

        <this.FormInput name="password" label="Password" type="password">
          <this.PasswordStrengthBar/>
        </this.FormInput>
        <this.FormInput name="passwordRepeat" label="Password (repeat)" type="password"/>

        {this.state._validation.token && <FormFeedback className="d-block mb-3">{this.state._validation.token}</FormFeedback>}

        {this.state._blocking ? <Spinner color="primary" /> : <Button color="success">Register</Button>}
      </Form>
    );
  }
}
