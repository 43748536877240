import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Card, CardImg, CardBody, CardText, CardFooter, CardTitle, UncontrolledTooltip } from 'reactstrap';

import { formatDateAvailable } from './Util';
import BuyButton from './BuyButton';


export default function Item(props) {
  return (
    <Card className={"pointer"}>
      <LinkContainer to={`/detail/${props.id}`}>
        <CardImg top src={`${process.env.REACT_APP_BACKEND_URL}${props.product_details.imageURI}`} alt=""/>
      </LinkContainer>
      <LinkContainer to={`/detail/${props.id}`}>
        <CardBody className="pb-0">
          <CardTitle tag="h4">
            {props.product_details.name}
          </CardTitle>
          <CardText className="mb-0">
            {formatDateAvailable(props.availableDate)}
          </CardText>
        </CardBody>
      </LinkContainer>
      <CardBody>
        <BuyButton {...props} states={props.states || []}/>
      </CardBody>


      {props.showFooter && <LinkContainer to={`/seller-profile/${props.seller}`}>
        <CardFooter id={`sellerName_${props.id}`} className="text-primary nl2br">
          {props.seller_details.name}
          {props.seller_details.publicDescription && <UncontrolledTooltip placement="top" target={`sellerName_${props.id}`}>
            <div className="text-left nl2br">
              {props.seller_details.publicDescription}
            </div>
          </UncontrolledTooltip>}
        </CardFooter>
      </LinkContainer>}
    </Card>
  );
};

Item.defaultProps = {
  showFooter: true,
};
