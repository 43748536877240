import React from 'react';
import { Button, Col, Row, Spinner, Container,
  Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import PaginationComponent from "react-reactstrap-pagination";

import auth from '../User/Auth';
import api from '../API';
import { getOrderStatus } from '../Util/OrderStatus';

const PAGE_SIZE = 20;


const Order = (props) => {
  const shipping = props.data.shipping || {};
  const orderStatus = getOrderStatus(props.status);

  return (
    <Row className="mb-1 text-left">
      <Col>
        <Card>
          <CardBody>
            <Container>
              <Row>
                <Col xs={4}>
                  <h6 className="text-left">Order #: {props.id}</h6>
                  <h6 className="text-left">{moment(props.created).format('l LT')}</h6>
                  <h6 className="text-left">{orderStatus.label}</h6>
                </Col>
                <Col xs={4}>
                  <h6 className="text-left">{shipping.name}</h6>
                  <h6 className="text-left">{props.seller_name}</h6>
                </Col>
                <Col xs={4}>
                  <Link to={`/${props.urlPrefix || ''}orders/${props.id}`}>
                    <Button>Details</Button>
                  </Link>
                  {' '}
                  {orderStatus.allowDownloadReceipt &&
                    <a href={`${process.env.REACT_APP_BACKEND_URL}/api/order/${props.id}/receipt?jwt=${auth.getAccessToken()}`}>
                      <Button>Download PDF</Button>
                    </a>
                  }
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}


export default class OrdersList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      _loading: true,
      orders: [],
      recordCount: 0,
      activePage: 1,
    };
  }

  async loadList(pageNumber) {
    const params = {limit: PAGE_SIZE};
    if (pageNumber) {
      params.offset = (pageNumber-1) * PAGE_SIZE;
    }

    const data = await api.get(`${this.urlPrefix || ''}order`, params);
    this.setState({
      orders: data.results,
      recordCount: data.count,
      activePage: pageNumber || 1,
      _loading: false,
    });
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    this.loadList();
  }

  render() {
    if (this.state._loading) return (<Spinner color="primary"/>);

    return (
      <Container>
        {(this.state.orders.length === 0) &&
          <p>You don't have any orders.</p>
        }

        {this.state.orders.map(e => (
          <Order key={e.id} urlPrefix={this.urlPrefix} {...e}/>
        ))}

        {(this.state.recordCount > PAGE_SIZE) &&
          <div className="mt-3 mb-1 d-flex justify-content-center">
            <PaginationComponent
              totalItems={this.state.recordCount}
              pageSize={PAGE_SIZE}
              activePage={this.state.activePage}
              onSelect={this.loadList.bind(this)}
            />
          </div>
        }
      </Container>
    );
  }
}
