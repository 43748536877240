import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import GreenWeightReport from './GreenWeightReport';
import FinancialReport from './FinancialReport';


export default function Reports(props) {
  const [activeTab, setActiveTab] = useState("Green Weight");

  const TabHeader = ({name}) => {
    return (
      <NavItem>
        <NavLink
          href="#"
          active={activeTab === name}
          onClick={() => setActiveTab(name)}
        >
          {name}
        </NavLink>
      </NavItem>
    );
  }

  return (
    <>
      <Nav pills>
        <TabHeader name="Green Weight"/>
        <TabHeader name="Financial (GST)"/>
      </Nav>
      <TabContent activeTab={activeTab} className="mt-3">
        <TabPane tabId="Green Weight">
          <GreenWeightReport/>
        </TabPane>
        <TabPane tabId="Financial (GST)">
          <FinancialReport/>
        </TabPane>
      </TabContent>
    </>
  );
};
