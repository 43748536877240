import React from 'react';
import { Button, Form, Spinner } from 'reactstrap';

import api from '../API.js';
import FormBase from '../Util/FormBase';


export default class Login extends FormBase {
  constructor(props) {
    super(props);
    this.state = {
      _validation: {},
    };
  }

  validate() {
    const errors = {}
    this.validateEmail('email', errors);
    return errors;
  }

  async onSubmit() {
    try {
      await api.callAPI(this, api.post('user/resetPassword', {email: this.state.email}));
      this.setState({_done: true});
    }
    catch(err) {
      // nothing to do, toast has been displayed
      console.log(err);
    }
  }

  render() {
    if (this.state._done) {
      return (
        <h6>Request submitted, please check your email.</h6>
      );
    }

    return (
      <Form onSubmit={this.handleSubmit.bind(this)}>
        <this.FormInput type="email" name="email" label="Email"
          formText="Please enter your email address that you used to register. A link to set a new password will be emailed to you."
        />
        {this.state._loading ? <Spinner color="primary"/> : <Button color="success">Reset password</Button>}
      </Form>
    );
  }
}
