import React from 'react';
import { FormGroup, Label, Input, FormFeedback, Button, Col, Spinner, Form, InputGroupAddon,
  Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import { toast } from 'react-toastify';

import api from '../API';
import auth from '../User/Auth';
import FormBase, { reactStateToDoc } from '../Util/FormBase';


const ShippingOption = (props) => (
  <FormGroup row>
    <Label for={`shippingName_${props.index}`} sm={2}>{`Shipping Option #${props.index+1}`}</Label>
    <Col sm={6}>
      <Input type="text" name={`shippingName_${props.index}`} id={`shippingName_${props.index}`}
        placeholder="e.g. Dunedin CBD and Mosgiel, etc."
        value={props.name || ''}
        invalid={!!props.validation.name}
        onChange={(evt) => {
          props.onChange({
            name: evt.target.value,
            cost: props.cost,
          });
        }}
      />
      <FormFeedback>{props.validation.name}</FormFeedback>
    </Col>
    <Col sm={4}>
      <Input type="text" name={`shippingCost_${props.index}`} id={`shippingCost_${props.index}`}
        placeholder="Cost"
        value={props.cost || ''}
        invalid={!!props.validation.cost}
        onChange={(evt) => {
          props.onChange({
            name: props.name,
            cost: parseFloat(evt.target.value),
          });
        }}
      />
      <FormFeedback>{props.validation.cost}</FormFeedback>
    </Col>
  </FormGroup>
);


class PickupSettings extends FormBase {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      _validation: {},
    };
  }

  setState(newState) {
    super.setState(newState, () => this.props.onChange(reactStateToDoc(this.state)));
  }

  render() {
    return (
      <React.Fragment>
        <this.FormInput type="custom" name="allowPickup" label="Allow Pick Up">
          <this.ButtonToggle name="allowPickup"/>
        </this.FormInput>
        {this.state.allowPickup &&
          <React.Fragment>
            <this.FormInput type="text" name="pickupAddress" label="Pick Up Address"
              formText="Enter address or GPS coordinates if place (wharf) doesn't have an address."
              placeholder="e.g. 123 Real pl, Realtown; or e.g. -45.8825856,170.5083426"/>
            <this.FormInput type="text" name="pickupNotes" label="Pick Up Notes"
              placeholder="e.g. A red Boaty Mc Boatface on far end of the wharf"/>
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}


export default class Settings extends FormBase {
  constructor(props) {
    super(props);
    this.state = {
      _loading: true,
      _validation: {},
      _activeTab: "User",
    };
  }

  async componentDidMount() {
    const data = await api.callAPI(this, api.get('seller/user'));
    // add 1 empty shipping option and vessel
    data.shipping.push({});
    data.vessels.push('');

    this.setState({
      shipping: data.shipping,
      vessels: data.vessels,
      pickup: data.pickup || {},
    });
  }

  validate() {
    const errors = {};
    this.validateRequired('name', errors);
    this.validateRequired('sellerName', errors);
    this.validateRequired('gstNumber', errors);
    this.validateRequired('phone', errors);

    const shipping = this.state.shipping.filter(e => !!e.name); // filter only named shipping options
    if (!this.state.pickup.allowPickup && !shipping.length) {
      // will be displayed under first input row for shipping
      errors.shipping = [{name: "Please enter at least one shipping option"}];
    }

    function validateShipping(shippingErrors, field, i) {
      shippingErrors[i] = {[field]: "This field cannot be empty"};
      return shippingErrors;
    }

    this.state.shipping.forEach((e, i) => {
      if (!e.name && e.cost) {
        errors.shipping = validateShipping(errors.shipping || [], "name", i);
      }
      if (e.name && !e.cost) {
        errors.shipping = validateShipping(errors.shipping || [], "cost", i);
      }
    });

    return errors;
  }

  async onSubmit() {
    const details = reactStateToDoc(this.state);
    details.shipping = details.shipping.filter(e => !!e.name); // filter only named shipping options
    details.vessels = details.vessels.filter(e => !!e); // filter only named vessels

    try {
      await api.callAPI(this, api.put(`seller/user`, details), false, '_blocking');
      toast.success('Details updated.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
    catch(err) {
      // already handled. Just don't propagate.
    }
  }

  fieldTabMap = {
    'name': 'User',
    'sellerName': 'Public Profile',
    'gstNumber': 'Ordering',
    'phone': 'Ordering',
    'shipping': 'Shipping',
  }
  onError(errors) {
    // find first error and activate the correct tab
    const field = Object.keys(errors)[0];
    if (field) {
      const tab = this.fieldTabMap[field];
      if (tab) {
        this.setState({_activeTab: tab});
      }
    }
  }

  async stripeLink() {
    this.setState({_blocking: true});
    try {
      const data = await api.get('seller/stripe_express_account/link');
      window.location.replace(data.url);
    } catch (err) {
      // toast already informed the user about the error
      this.setState({_blocking: false});
    }
  }

  async inviteUser(endpointUri) {
    const errors = {};
    this.validateEmail('inviteUserEmail', errors);
    this.setState({_validation: errors});

    if (this.validationSuccessful(errors)) {
      this.setState({_blocking: true});
      try {
        await api.post(endpointUri, {
          email: this.state.inviteUserEmail,
        });
        toast.success(`User ${this.state.inviteUserEmail} invited.`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        });
        this.setState({inviteUserEmail: null});
      } finally {
        // toast already informed the user about the error
        this.setState({_blocking: false});
      }
    }
  }

  TabHeader = (props) => {
    const {name} = props;
    return (
      <NavItem>
        <NavLink
          href="#"
          active={this.state._activeTab === name}
          onClick={() => this.setState({_activeTab: name})}
        >
          {name}
        </NavLink>
      </NavItem>
    );
  }

  render() {
    if (this.state._loading) {
      return <Spinner color="primary"/>;
    }
    return (
      <Form onSubmit={this.handleSubmit.bind(this)}>
        <Nav pills>
          <this.TabHeader name="User"/>
          <this.TabHeader name="Public Profile"/>
          <this.TabHeader name="Ordering"/>
          <this.TabHeader name="Shipping"/>
          <this.TabHeader name="Other"/>
        </Nav>

        <TabContent activeTab={this.state._activeTab} className="mt-3">
          <TabPane tabId="User">
            <this.FormInput type="text" name="name" label="Your Name"
              formText="This is how we will call you."
              placeholder="e.g. Homer Simpson"/>

            <this.FormInput type="email" name="email" label="Email" disabled={true}
              placeholder="e.g. homer@simpson.com"/>
          </TabPane>

          <TabPane tabId="Public Profile">
            <this.FormInput type="text" name="sellerName" label="Account Name"
              formText="This will be publicly visible to customers. Choose a name that represents you as a business."
              placeholder="e.g. FishHook Dunedin"/>
            <this.FormInput type="textarea" name="publicDescription" label="Public Profile"
              formText="This will be publicly visible. Put basic info about yourself or your business. Don't put contact details here."
              placeholder="e.g. shop opening hours, what kind of fish you usually have, how often you fish, etc."/>
          </TabPane>

          <TabPane tabId="Ordering" id="Ordering">
            <this.FormInput type="textarea" name="customerNote" label="Note for Customers"
              formText="Customers will see this after they order from you. Put useful info such as contact details (if appropriate)."
              placeholder="e.g. contact details for customers to arrange pickup "/>

          <this.FormInput type="text" name="permitNumber" label="Fishing Permit Number"
            formText="This is your FishServe client number. This will appear on customer receipts."
            placeholder="e.g. 123456"/>

            {(this.state.vessels || []).map((e, i) => (
              <this.FormInput type="text" name={`vessel_${i}`} label={`Vessel #${i+1}`}
                formText="Vessel name will appear on customer receipts."
                placeholder="e.g. Boaty McBoatFace"
                value={e}
                onChange={(event) => {
                  const value = event.target.value;
                  this.state.vessels[i] = value;
                  if (this.state.vessels.length-1 === i) {
                    this.state.vessels.push('');
                  }
                  this.setState({vessels: this.state.vessels});
                }}
              />
            ))}

            <this.FormInput type="custom" name="gstRegistered" label="GST Registered">
              <this.ButtonToggle name="gstRegistered"/>
            </this.FormInput>
            <this.FormInput type="text" name="gstNumber" label={this.state.gstRegistered ? "GST Number" : "IRD Number"}
              placeholder="e.g. 123-456-789"/>

            <this.FormInput type="text" name="phone" label="Phone Number"
              formText="In case we need to contact you."
              placeholder="e.g. 0210123456"/>

            <this.FormInput type="number" name="orderLimitMin" label="Order Limit Min."
              formText="If you want to limit the minimum ordered value of fish in $."
              placeholder="e.g. 25"/>
            <this.FormInput type="number" name="orderLimitMax" label="Order Limit Max."
              formText="If you're using Wharf Sales, you need to limit the maximum ordered amount of fish (greenweight kg)."
              placeholder="e.g. 10"/>

            <this.FormInput type="custom" label="Stripe Link">
              <div className="row">
                {this.state._blocking ? <Spinner color="primary"/> :
                  (this.state.stripeAccount && this.state.stripeAccount.charges_enabled && this.state.stripeAccount.payouts_enabled) ?
                    <a
                      href={`${process.env.REACT_APP_BACKEND_URL}/api/seller/stripe_express_account/dashboard/?jwt=${auth.getAccessToken()}`}
                      className="btn btn-secondary mx-3" role="button" target="_blank" rel="noreferrer"
                    >
                      Stripe Dashboard
                    </a>
                  :
                    <Button color="primary" className="mx-3" onClick={this.stripeLink.bind(this)}>Stripe Onboarding</Button>
                }
                <p>
                  {this.state.stripeAccount ? (
                    this.state.stripeAccount.charges_enabled && this.state.stripeAccount.payouts_enabled ?
                      <>
                        <span className="text-success">All done, you're ready to receive payments!</span>
                      </>
                    :
                      <span className="text-danger">Missing information, please complete the onboarding.</span>
                  ) : (
                    <span className="text-danger">Please set up Stripe account.</span>
                  )}
                </p>
              </div>
            </this.FormInput>
          </TabPane>

          <TabPane tabId="Shipping">
            <PickupSettings
              {...this.state.pickup}
              onChange={pickup => this.setState({pickup: pickup})}
            />

            {(this.state.shipping || []).map((e, i) => (
              <ShippingOption
                {...e}
                key={`shipping_${i}`}
                index={i}
                onChange={(state) => {
                  this.state.shipping[i] = state;
                  if (this.state.shipping.length-1 === i) {
                    this.state.shipping.push({});
                  }
                  this.setState({shipping: this.state.shipping});
                }}
                validation={(this.state._validation.shipping || [])[i] || {}}
              />
            ))}
          </TabPane>

          <TabPane tabId="Other">
            <this.FormInput type="text" name="inviteUserEmail" label="Invite User" inputGroup
              formText="Enter the user's email address to send an invitation."
              placeholder="e.g. marge@simpson.com"
            >
              <InputGroupAddon addonType="append">
                <Button
                  color="secondary"
                  className="mr-3"
                  onClick={() => this.inviteUser('seller/user/invite')}
                  disabled={!this.state.inviteUserEmail || this.state._blocking}
                >
                  Invite
                </Button>
              </InputGroupAddon>
            </this.FormInput>
          </TabPane>
        </TabContent>

        {this.state._blocking ? <Spinner color="primary"/> : <Button color="success">Update</Button>}
      </Form>
    );
  }
}
