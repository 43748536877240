import axios from 'axios';
import { ValidationException } from './Util/Util';
import { toast } from 'react-toastify';

import auth from './User/Auth';

class API {
  API_URL = `${process.env.REACT_APP_BACKEND_URL}/api`;

  headers() {
    const token = auth.getAccessToken();
    return token ? {"Authorization" : `Bearer ${token}`} : {};
  }

  async get(name, params={}) {
    const options = {
      method: "GET",
      params: params,
      headers: this.headers(),
      url: `${this.API_URL}/${name}/`,
    };
    try {
      const data = await axios(options);
      return data.data;
    } catch (err) {
      this._toast(err);
      throw err; // prevent unwanted continuation of the caller function
    }
  }

  validateStatus(status) {
    return (status >= 200 && status < 300) // default
      || (status === 400); // validation failed
  }

  _toast(err) {
    console.log(err);
    toast.error('Something went wrong: ' + err, {
      position: "top-center",
      autoClose: 15000,
      hideProgressBar: true,
      closeOnClick: true,
    });
  }

  async _exec_save(url, method, data) {
    const options = {
      method: method,
      url: url,
      headers: {
        'content-type': 'application/json',
        ...this.headers(),
      },
      data: data,
      validateStatus: this.validateStatus,
    };
    var result;
    try {
      result = await axios(options);
    } catch (err) {
      this._toast(err);
      throw err; // prevent unwanted continuation of the caller function
    }

    if (result.status === 400) {
      throw new ValidationException(result.data);
    }
    return result.data;
  }

  async post(name, data) {
    return this._exec_save(`${this.API_URL}/${name}/`, "POST", data);
  }

  async put(name, data) {
    return this._exec_save(`${this.API_URL}/${name}/`, "PUT", data);
  }

  async patch(name, data) {
    return this._exec_save(`${this.API_URL}/${name}/`, "PATCH", data);
  }

  async del(name) {
    return this._exec_save(`${this.API_URL}/${name}/`, "DELETE");
  }

  async callAPI(component, apiCall, setState=true, loaderProp='_loading') {
    try {
      component.setState({[loaderProp]: true});
      const data = await apiCall;
      if (setState) {
        component.setState(data);
      }
      return data;
    }
    catch (err) {
      if (err instanceof ValidationException) {
        component.setState({_validation: {
          ...err.data,
        }});
      }
      // toast have been displayed already
      throw err; // prevent unwanted continuation of the caller function
    }
    finally {
      component.setState({[loaderProp]: false});
    }
  }

};

export default new API();
