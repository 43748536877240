import React from 'react';
import { Button, Form } from 'reactstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import auth from './Auth.js';
import FormBase from '../Util/FormBase';
import { ValidationException } from '../Util/Util';


export default class Login extends FormBase {
  constructor(props) {
    super(props);
    this.state = {
      _validation: {}
    };
  }

  validate() {
    const errors = {}

    this.validateEmail('email', errors);
    this.validateRequired('password', errors);

    return errors;
  }

  async onSubmit() {
    var history = this.props.history;
    var loginData = {
      username: this.state.email,
      password: this.state.password,
    };

    try {
      await auth.login(loginData);
      const prevLocation = this.props.location.state && this.props.location.state.prevLocation;
      history.replace(prevLocation || (auth.isSeller() ? "/seller/list" : "/"));

      toast.success('You are logged in.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
    catch(err) {
      if (err instanceof ValidationException) {
        this.setState({_validation: {email: (err.data && err.data.detail) || "Login failed."}});
      }
      else {
        console.log(err);
        toast.error('Something went wrong: ' + err, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    }
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit.bind(this)}>
        <this.FormInput type="email" name="email" label="Email"/>
        <this.FormInput name="password" label="Password" type="password"/>
        <Button color="success">Sign In</Button>
        <h6 className="mt-3 mb-2 text-secondary">--- OR ---</h6>
        <Link to={{
          pathname: "/register",
          state: {
            prevLocation: this.props.location && this.props.location.state && this.props.location.state.prevLocation,
          },
        }}>
          <Button color="primary">Register New Account</Button>
        </Link>
        <br/>
        <Link to="/resetPassword">
          <Button color="link">Forgotten Password?</Button>
        </Link>
      </Form>
    );
  }
}
