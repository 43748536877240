import axios from 'axios';

import { ValidationException } from '../Util/Util';

function decodeToken(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

async function post(uri, data) {
  function validateStatus(status) {
    return (status >= 200 && status < 300) // default
      || (status === 400) // validation failed
      || (status === 401); // authentication failed
  }

  const options = {
    method: "POST",
    url: `${process.env.REACT_APP_BACKEND_URL}/api/${uri}/`,
    headers: {
      'content-type': 'application/json',
    },
    data: data,
    validateStatus: validateStatus,
  };

  const result = await axios(options);

  if ((result.status === 400) || (result.status === 401)) {
    throw new ValidationException(result.data);
  }

  return result.data;
}

class Auth {
  TOKEN_KEY = 'AUTH_TOKEN';

  constructor() {
    this.refreshToken();
    setInterval(this._refreshAuthStatus.bind(this), 60000);
  }

  _getToken() {
    try {
      return JSON.parse(localStorage.getItem(this.TOKEN_KEY)) || {};
    }
    catch (err) {
      return {};
    }
  }

  _refreshAuthStatus() {
    if (this.isAuthenticated()) {
      const token = this._getToken();
      var now = new Date().getTime();
      if (now > token.refresh_expires_at) {
        // oops tokens have expired, no other choice than to log out!
        this.logout();
        return;
      }

      if ((token.expires_at - now) < 6 * 60 * 60 * 1000) {
        // refresh token 6 hours before it expires
        this.refreshToken();
      }
    }
  }

  _handleToken(response) {
    const accessTokenData = decodeToken(response.access);
    const refreshTokenData = decodeToken(response.refresh);

    localStorage.setItem(this.TOKEN_KEY, JSON.stringify({
      // the encoded tokens to be used in Auth headers
      access_token: response.access,
      refresh_token: response.refresh,
      // handy data for this app
      expires_at: new Date(accessTokenData.exp * 1000).getTime(),
      refresh_expires_at: new Date(refreshTokenData.exp * 1000).getTime(),
      is_seller: accessTokenData.is_seller,
      is_wholesale: accessTokenData.is_wholesale,
      is_admin: accessTokenData.is_admin,
    }))
  }

  async login(data) {
    // will throw ValidationException if login failed
    const response = await post("token", data);
    this._handleToken(response);
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem(this.TOKEN_KEY);

    window.location.replace('/login');
  }

  async refreshToken() {
    if (this.isAuthenticated()) {
      const token = this._getToken();
      const data = {
        refresh: token.refresh_token
      };

      try {
        const response = await post("token/refresh", data);
        this._handleToken(response);
      } catch (err) {
        if (err instanceof ValidationException) {
          // HTTP 400
          this.logout(); // I was logged in, but somehow got kicked out
        }
      }
    }
  }

  isAuthenticated() {
    // The user is logged in and able to refresh the access token.
    // The access token can be expired but it'll be refreshed automatically
    const token = this._getToken();
    let expiresAt = token.refresh_expires_at || 0;
    var now = new Date().getTime();
    return (now < expiresAt);
  }

  isSeller() {
    const token = this._getToken();
    return this.isAuthenticated() && token.is_seller;
  }

  isWholesale() {
    const token = this._getToken();
    return this.isAuthenticated() && token.is_wholesale;
  }

  isAdmin() {
    const token = this._getToken();
    return this.isAuthenticated() && token.is_admin;
  }

  getAccessToken() {
    const token = this._getToken();
    let expiresAt = token.expires_at || 0;
    var now = new Date().getTime();
    if (now < expiresAt) { // do not serve expired tokens
      return token.access_token;
    }
    else {
      // TODO re-authenticate
      return null;
    }
  }

  getUserDetails() {
    return decodeToken(this.getAccessToken());
  }

  getUsersShortName() {
    const details = this.getUserDetails();
    return (details.name && details.name.split(' ')[0]) // extract first name
      || (details.email && details.email.split('@')[0]) // extract name from email
      || ""; // no details in the JWT
  }
}

export default new Auth();
