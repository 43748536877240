import React from 'react';
import { Button, FormGroup, Label, Input, Container, Col, Row,
  Card, CardImg, CardBody, CardText, FormText } from 'reactstrap';
import { GiTrashCan as TrashIcon } from 'react-icons/gi';
import { Link } from 'react-router-dom';

import { formatDateAvailable } from '../Catalog/Util';
import { formatQuantity, formatCost } from '../Util/Util';
import cartData from './Cart';
import auth from '../User/Auth';
import Linkify from '../Util/Linkify';


const CartItem = (props) => {
  return (
    <Card>
      <CardBody>
        <Container>
          <Row>
            <Col xs={12} sm={4} lg={3}>
              <Card>
                <CardImg top className="img-fluid" src={`${process.env.REACT_APP_BACKEND_URL}${props.product.imageURI}`} alt=""/>
              </Card>
            </Col>
            <Col xs={12} sm={8} lg={3}>
              <CardText className="text-left">
                {props.product.name}
                <br/>
                {`${props.state.name} ${formatCost(props.wholesale ? props.state.priceGstExcl : props.state.priceGstIncl)}/kg`}
              </CardText>
            </Col>
            <Col xs={6} sm={6} lg={3}>
              <FormGroup row className="mb-0">
                <Col>
                  <Input name="weight" type="number" className="kg"
                    step={0.5}
                    defaultValue={props.quantity.toFixed(1)}
                    onChange={(evt) => {
                      props.onUpdateQuantity(parseFloat(evt.target.value) || 0);
                    }}
                  />
                </Col>
                <Label for="weight">kg</Label>
              </FormGroup>
              <FormText className="text-left">
              {`${formatQuantity(props.quantityAvailable / props.state.conversionFactor)}kg available`}
              </FormText>
            </Col>
            <Col xs={6} sm={6} lg={3}>
              <CardText className="text-right">
                {formatCost(props.wholesale ? props.totalPriceGstExcl : props.totalPriceGstIncl)}
                <br/>
                <TrashIcon size="2em" color="FireBrick" className="ml-3 pointer"
                  onClick={props.onItemRemoved}/>
              </CardText>
            </Col>
          </Row>
          {props.validationMessage &&
            <Row>
              <Col xs={12}>
                <CardText className="text-left font-weight-bold text-danger">{props.validationMessage}</CardText>
              </Col>
            </Row>
          }
        </Container>
      </CardBody>
    </Card>
  );
};


const Total = (props) => {
  const gst = props.totalGstIncl - props.totalGstExcl;


  return (
    <Card className="mb-4">
      <CardBody>
        <Container>
          {props.validationMessage &&
            <Row>
              <Col xs={12}>
                <CardText className="text-left font-weight-bold text-danger mb-3">
                  <Linkify>
                    {props.validationMessage}
                  </Linkify>
                </CardText>
              </Col>
            </Row>
          }
          {props.wholesale &&
            <Row>
              <Col>
                <CardText className="text-left font-weight-bold">{"Total (Excl. GST)"}</CardText>
              </Col>
              <Col xs={5} sm={4} lg={3}>
                <CardText className="text-right font-weight-bold">
                  {formatCost(props.totalGstExcl)}
                </CardText>
              </Col>
            </Row>
          }
          {gst &&
            <Row>
              <Col>
                <CardText className="text-left">{props.wholesale ? "GST" : "GST Included"}</CardText>
              </Col>
              <Col xs={5} sm={4} lg={3}>
                <CardText className="text-right">
                  {formatCost(gst)}
                </CardText>
              </Col>
            </Row>
          }
          <Row>
            <Col>
              <CardText className="text-left font-weight-bold">{"Total"} {props.wholesale && "(Incl. GST)"}</CardText>
            </Col>
            <Col xs={5} sm={4} lg={3}>
              <CardText className="text-right font-weight-bold">
                {formatCost(props.totalGstIncl)}
              </CardText>
            </Col>
          </Row>
        </Container>
        <Button
          color="primary"
          className="float-right mt-2 mb-2"
          onClick={props.onSubmit}
        >
          Buy from {props.sellerName}
        </Button>
      </CardBody>
    </Card>
  );
};


export default function CartList(props) {
  const wholesale = auth.isWholesale();
  const valid = props.cartData.length > 0;
  return valid ? (
    <div>
      {(props.cartData.length > 1) && <h5 className="text-warning mb-4">Your cart has been split into {props.cartData.length} orders for different sellers and delivery date. Each order needs to be placed separately.</h5>}
      {props.cartData.map((group, i) => (
        <React.Fragment key={i}>
          <h5 className="text-left">{group.seller_name}, {formatDateAvailable(group.availableDate)}</h5>
          {group.items.map((e) => (
            <CartItem key={e.index} {...e.entry}
              wholesale={wholesale}
              onUpdateQuantity={(qty) => {
                cartData.updateQty(e.index, qty);
                props.onCartUpdate(cartData.getCartDataGrouped());
              }}
              onItemRemoved={(qty) => {
                cartData.remove(e.index);
                props.onCartUpdate(cartData.getCartDataGrouped());
              }}
            />
          ))}

          <Total
            sellerName={group.seller_name}
            totalGstIncl={group.totalGstIncl}
            totalGstExcl={group.totalGstExcl}
            wholesale={wholesale}
            validationMessage={group.validationMessage}
            onSubmit={() => props.onSubmit(group, i)}/>
        </React.Fragment>
      ))}
    </div>
  ) : (
    <h4>Your cart is empty. Please <Link to="/">add some fish</Link> to your cart.</h4>
  );
}
