import React from 'react';
import { Col, Row, Container, Card, CardHeader, CardBody, CardText,
  Spinner } from 'reactstrap';
import * as moment from 'moment';

import api from '../API';
import auth from '../User/Auth';
import { formatCost, GST } from '../Util/Util';
import Item from './Item';
import PastItem from './PastItem';


export function ShippingInfo(props) {
  return (
    <Card outline color="secondary" className="mb-3">
      <CardHeader>
        Shipping/Pickup Info
      </CardHeader>

      <CardBody className="text-left">
        {props.shipping && props.shipping.length > 0 &&
          props.shipping.map((e, i) => (
            <div key={i} className="mb-0">
              {e.name} - {formatCost(auth.isWholesale() ? (e.cost / GST) : e.cost)}
            </div>
          ))
        }
        {props.pickup && props.pickup.pickupAddress &&
          <>
            <div>Pickup: {props.pickup.pickupAddress}</div>
            <iframe
              title="Pick Up Address"
              width="100%"
              height="300"
              frameBorder="0" style={{border:0}}
              src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=${props.pickup.pickupAddress}`}
              allowFullScreen>
            </iframe>
            <div>{props.pickup.pickupNotes}</div>
          </>
        }
      </CardBody>
    </Card>
  );
}

export default class SellerProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      _loading: true,
      _loadingListings: true,
    };
  }

  async loadSellerDetails() {
    if (this.props.sellerId) {
      await api.callAPI(this, api.get(`seller/${this.props.sellerId}`));
    }
  }

  async loadCatalog() {
    this.setState({_loadingListings: true});

    const stock = await api.callAPI(this, api.get('stock', {
      seller_id: this.props.sellerId,
      availableDate__gte: moment().format("YYYY-MM-DD"),
    }), false);
    this.setState({_loadingListings: false, stock: stock});
  }

  async loadPastSales() {
    const stock = await api.callAPI(this, api.get('stock', {
      seller_id: this.props.sellerId,
      availableDate__gte: moment().subtract(1, 'months').format("YYYY-MM-DD"),
      availableDate__lt: moment().format("YYYY-MM-DD"),
      ordering: '-availableDate',
    }), false);
    this.setState({pastSales: stock});
  }

  componentDidMount() {
    this.loadSellerDetails();
    this.loadCatalog();
    this.loadPastSales();
  }

  render() {
    return (
      <Container>
        <Row>
          {this.state._loading ? <Spinner color="primary"/> :
            <Col lg={6}>
              <Card outline color="secondary" className="mb-1">
                <CardHeader tag="h3">
                  {this.state.name}
                </CardHeader>
                {this.state.publicDescription && <CardBody>
                  <CardText className="text-left nl2br">{this.state.publicDescription}</CardText>
                </CardBody>}
              </Card>

              <ShippingInfo {...this.state}/>
            </Col>
          }

          <Col lg={6}>
            <Row>
              {this.state._loadingListings ? <Spinner color="primary"/> :
                this.state.stock.map(e => (
                  <Col key={e.id} xl={6} className="mb-4">
                    <Item {...e} showFooter={false}/>
                  </Col>
                ))
              }
            </Row>
          </Col>

        </Row>

        {this.state.pastSales && this.state.pastSales.length>0 &&
          <Row className="mt-4">
            <Col>
              <h5>Past listings</h5>
            </Col>
          </Row>
        }
        <Row>
          {!this.state.pastSales ? <Spinner color="primary"/> :
            this.state.pastSales.map(e => (
              <PastItem key={e.id} {...e} showFooter={false}/>
            ))
          }
        </Row>
      </Container>
    );
  }
}
