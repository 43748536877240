import React from 'react';
import { Button, Container, Col, Row,
  Card, CardImg, CardBody } from 'reactstrap';
import confirm from "reactstrap-confirm";

import api from '../API';
import ProductState from './ProductState';
import {roundQuantity} from '../Util/Util';


export default function Item(props) {
  const onDelete = async () => {
    const result = await confirm({
      message: "This will remove the unsold quantity from the e-shop. Are you sure you want that?",
      confirmText: "Yes, remove",
      confirmColor: "danger",
      cancelColor: "primary",
      cancelText: "No, keep it",
    });
    if (result) {
      await api.patch(`seller/stock/${props.id}`, {quantityListed: props.quantityListed - props.quantityAvailable});
      props.onDeleted();
    }
  };

  const quantitySold = roundQuantity(props.quantityListed - props.quantityAvailable);

  return (
    <Row className="mb-4 text-left">
      <Col>
        <Card>
          <CardBody>
            <Container>
              <Row>
                <Col xs={12} sm={4} lg={3}>
                  <Card>
                    <CardImg top className="img-fluid" src={`${process.env.REACT_APP_BACKEND_URL}${props.product_details.imageURI}`} alt=""/>
                  </Card>
                </Col>
                <Col xs={12} sm={8} lg={4}>
                  <h5 className="text-left">{props.product_details.name}</h5>
                  {(props.product_details.states || []).map(state => (<ProductState key={state.id} {...state}/>))}
                </Col>
                <Col xs={12} sm={6} lg={2}>
                  <h4 className="text-left">{roundQuantity(props.quantityListed)} kg</h4>
                  <h6 className="text-left">{quantitySold} kg sold</h6>
                </Col>
                <Col xs={12} sm={6} lg={3} className="text-right">
                  <Button className="mb-1" onClick={props.onEdit}>Edit Listing</Button><br/>
                  {(props.quantityAvailable > 0.1) && <Button color="danger" onClick={onDelete}>Expire Listing</Button>}
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
