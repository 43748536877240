import React from 'react';
import { Button, Spinner, Table } from 'reactstrap';
import * as moment from 'moment';

import api from '../API';
import { formatQuantity } from '../Util/Util';


export default class GreenWeightReport extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      _loading: true,
      rows: [],
      month: null,
    };
  }

  async loadList() {
    this.setState({_loading: true});
    const params = {};
    if (this.state.month) {
      params.month = this.state.month;
    }
    const rows = await api.callAPI(this, api.get('seller/greenWeightSummary', params), false)

    this.setState({_loading: false, rows: rows});
  }

  componentDidMount() {
    this.loadList();
  }

  setMonth(date) {
    this.setState({month: date}, this.loadList.bind(this));
  }

  render() {
    var lastDate = null; // to hide duplicate dates
    return (
      <React.Fragment>
        {this.state._loading ? <Spinner color="primary"/> :
          <>
            <Table hover>
              <thead className="thead-dark">
                <tr>
                  <th className="text-right">Date</th>
                  <th className="text-left">Species</th>
                  <th className="text-right">Green Weight (kg)</th>
                </tr>
              </thead>
              <tbody>
                {this.state.rows.map(row => {
                  const date = row.date === lastDate ? null : row.date;
                  lastDate = row.date;
                  return (
                    <tr key={`${row.date}_${row.species}`} className="table-secondary">
                      <td className="text-right font-weight-bold">
                        {date && (
                          this.state.month ?
                            moment(date).format("D MMM YYYY")
                          :
                            <Button color="link" className="p-0" onClick={() => this.setMonth(date)}>{moment(date).format("MMM YYYY")}</Button>
                        )}
                      </td>
                      <td className="text-left">{row.species}</td>
                      <td className="text-right">{formatQuantity(row.greenweight || 0)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {this.state.month && <Button className="mb-1" color="primary" onClick={() => this.setMonth(null)}>Back to Summary</Button>}
          </>
        }
      </React.Fragment>
    );
  }
}
