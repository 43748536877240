import React from 'react';

import { formatCost } from '../Util/Util';


export default function ProductState(props) {
  return (
    <React.Fragment>
      {props.price &&
        <h6 className="text-left">
          {props.name}, Retail {formatCost(props.price)}/kg
        </h6>
      }
      {props.priceWholesale &&
        <h6 className="text-left">
          {props.name}, Wholesale {formatCost(props.priceWholesale)}/kg
        </h6>
      }
    </React.Fragment>
  );
}
