const statuses = {
  'pending': {
    label: 'Pending Payment',
    itemQuantityField: 'quantity',
    itemPriceGstExclField: 'totalPriceGstExcl',
    itemPriceGstInclField: 'totalPriceGstIncl',
    totalPriceGstExclField: 'totalGstExcl',
    totalPriceGstInclField: 'totalGstIncl',
  },
  'confirmed': {
    label: 'Confirmed',
    itemQuantityField: 'quantity',
    itemPriceGstExclField: 'totalPriceGstExcl',
    itemPriceGstInclField: 'totalPriceGstIncl',
    totalPriceGstExclField: 'totalGstExcl',
    totalPriceGstInclField: 'totalGstIncl',
  },
  'ready': {
    label: 'Ready for Delivery',
    itemQuantityField: 'quantityProvided',
    itemPriceGstExclField: 'totalPriceActualGstExcl',
    itemPriceGstInclField: 'totalPriceActualGstIncl',
    totalPriceGstExclField: 'totalActualGstExcl',
    totalPriceGstInclField: 'totalActualGstIncl',
    allowDownloadReceipt: true,
  },
  'delivered': {
    label: 'Delivered',
    itemQuantityField: 'quantityProvided',
    itemPriceGstExclField: 'totalPriceActualGstExcl',
    itemPriceGstInclField: 'totalPriceActualGstIncl',
    totalPriceGstExclField: 'totalActualGstExcl',
    totalPriceGstInclField: 'totalActualGstIncl',
    allowDownloadReceipt: true,
  },
  'cancelled': {
    label: 'Cancelled',
    itemQuantityField: 'quantity',
    itemPriceGstExclField: 'totalPriceGstExcl',
    itemPriceGstInclField: 'totalPriceGstIncl',
    totalPriceGstExclField: 'totalGstExcl',
    totalPriceGstInclField: 'totalGstIncl',
  },
  'payment_failed': {
    label: 'Cancelled, Payment Failed',
    itemQuantityField: 'quantity',
    itemPriceGstExclField: 'totalPriceGstExcl',
    itemPriceGstInclField: 'totalPriceGstIncl',
    totalPriceGstExclField: 'totalGstExcl',
    totalPriceGstInclField: 'totalGstIncl',
  },
  'unknown': {
    label: 'Unknown',
    itemQuantityField: 'quantity',
    itemPriceGstExclField: 'totalPriceGstExcl',
    itemPriceGstInclField: 'totalPriceGstIncl',
    totalPriceGstExclField: 'totalGstExcl',
    totalPriceGstInclField: 'totalGstIncl',
  },
}

export function getOrderStatus(text) {
  return statuses[text] || statuses.unknown;
}
