import React, { Component } from 'react';
import auth from './Auth.js';

class Logout extends Component {
  componentWillMount() {
    auth.logout(this.props.history);
    this.props.history.replace('/');
  }

  render() {
    return (<div/>);
  }
}

export default Logout;
