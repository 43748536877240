import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


export default function DropdownComponent(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      size={props.size}
    >
      <DropdownToggle caret block
        color={props.color}
        className="btn-ellipsis"
      >
        {(props.value && props.options && (props.options.find(e => e.id === props.value) || {}).name) ||  props.label}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => props.onChange(null)}>{props.emptyLabel || "All"}</DropdownItem>
        {(props.options || []).map(o => (
          <DropdownItem key={o.id} onClick={() => props.onChange(o.id)}>{o.name}</DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
