import React, { useState } from 'react';
import { Button, FormGroup, FormText, Label, Input, Container, Col, Row, CardText } from 'reactstrap';
import * as moment from 'moment';

import { formatQuantity, formatCost } from '../Util/Util';
import cart from '../Cart/Cart';
import auth from '../User/Auth';

function getPrice(state) {
    return auth.isWholesale() ? state.priceGstExcl : state.priceGstIncl;
}

export default function BuyButton(props) {
  const [kg, updateKg] = useState(1);
  const [stateIndex, updateStateIndex] = useState(0);

  // show only states that have price
  const states = props.states.filter(e => e.priceGstExcl); // if GSTExcl is filled, GSTIncl will be, too.

  const addToCart = (evt) => {
    evt.preventDefault();
    const state = states[stateIndex];
    cart.addToCart(props, state, kg);
  };

  if (states.length === 0) {
    return null; // no states defined - shouldn't really happen
  }

  const state = states[stateIndex];

  const today = moment().format('YYYY-MM-DD');

  if ((props.quantityAvailable < 0.1) || (props.availableDate < today)) {
    return (
      <CardText className="text-danger">
        {"Sold Out!"}
      </CardText>
    )
  }
  return (
    <Container className="pl-0 pr-0">
      <Row>
        <Col xs={12} className="pl-1">
          <FormGroup>
            <Input type="select" name="state" id="state"
              value={stateIndex}
              onChange={evt => updateStateIndex(parseInt(evt.target.value))}
            >
              {states.map((e, i) => getPrice(e) && (
                <option key={i} value={i}>{e.name} {formatCost(getPrice(e))}/kg</option>
              ))}
            </Input>
            <FormText className="text-left">
              {state.description}
              <br/>
              {`${formatQuantity(props.quantityAvailable / state.conversionFactor)}kg available`}
            </FormText>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6} className="pl-1">
          <FormGroup row>
            <Col>
              <Input type="number" value={kg} name="quantity"
                step={0.5}
                onChange={(evt) => updateKg(parseFloat(evt.target.value))}/>
            </Col>
            <Label for="kg">kg</Label>
          </FormGroup>
        </Col>
        <Col xs={6} className="pr-0">
          <Button color="primary" onClick={addToCart}>Buy</Button>
        </Col>
      </Row>
    </Container>
  );
}
