import React from 'react';
import { Button, Spinner,
  Modal, ModalHeader, ModalBody, ModalFooter,
  ListGroup, ListGroupItem } from 'reactstrap';
import { withRouter } from "react-router-dom";

import api from './API';

export function getCurrentRegionId() {
  const regionId = localStorage.getItem("regionId");
  return regionId ? parseInt(regionId) : null;
}

class RegionPicker extends React.Component {
  state = {
    _loading: true,
    isOpen: false,
    regionId: getCurrentRegionId(),
    regionName: null
  };

  _show() {
    // don't show in seller's and admin interfaces
    return (!this.props.location.pathname.startsWith("/seller") &&
      !this.props.location.pathname.startsWith("/admin"));
  }

  async componentDidMount() {
    // open immediately if region is not set, but not for sellers!
    if (!getCurrentRegionId() && this._show()) {
      this.setState({isOpen: true});
    }
    if (this.state.regionId) {
      const region = await api.get(`region/${this.state.regionId}`);
      this.setState({regionName: region.name});
    }
  }

  async onOpen() {
    this.setState({
      _loading: true,
    });

    const regions = await api.get('region');
    this.setState({
      regions: regions,
      _loading: false,
    });
  }

  onClose() {
    this.setState({isOpen: false});
  }

  selectRegion(i) {
    const region = this.state.regions[i];

    this.setState({
      regionId: region.id,
      regionName: region.name,
    });
    this.onClose();

    if (this.props.onSelect) {
      this.props.onSelect(region.id);
    }
    else {
      localStorage.setItem("regionId", region.id);
      this.props.history.go(0);
    }
  }

  render() {
    if (!this._show()) {
      return null;
    }

    const props = this.props;
    return (
      <React.Fragment>
        <Button
          outline={!!this.state.regionId}
          className="dropdown-toggle"
          color={this.state.regionId ? "navbar" : "danger"}
          onClick={() => this.setState({isOpen: true})}
        >
          {this.state.regionName ? `${this.state.regionName}` : "Choose Region" }
        </Button>
        <Modal isOpen={this.state.isOpen} toggle={this.onClose.bind(this)} onOpened={this.onOpen.bind(this)}>
          <ModalHeader toggle={props.onClose}>Choose your region</ModalHeader>
          <ModalBody>
            {this.state._loading ? <Spinner color="primary"/> :
              <React.Fragment>
                <h6 className="text-info">Please choose your region so we can display fish available to you.</h6>
                <ListGroup>
                {this.state.regions.map((e, i) => (
                  <React.Fragment key={i}>
                    <ListGroupItem tag="a" href="#"
                      onClick={() => this.selectRegion(i)}
                    >
                      {e.name}
                    </ListGroupItem>
                  </React.Fragment>
                ))}
                </ListGroup>
              </React.Fragment>
            }
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.onClose.bind(this)}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(RegionPicker);
