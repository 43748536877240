import React from 'react';
import { Button, Input, Form, Spinner, FormGroup, Label, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import FormBase, { reactStateToDoc } from '../Util/FormBase';
import auth from '../User/Auth';
import api from '../API';
import { formatCost, GST } from '../Util/Util';


class ShippingOptions extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.seller !== prevProps.seller) {
      // pre-select the first option from the list when seller changes. Each seller has their own shipping options.
      if (this.props.seller.pickup && this.props.seller.pickup.allowPickup) {
        this.props.onChange('pickup', null);
      }
      else {
        this.props.onChange(0, (this.props.seller.shipping || [])[0]);
      }
    }
  }

  render() {
    if (!this.props.seller) {
      return <Spinner color="primary"/>;
    }
    return (
      <Input type="select" name="deliveryOption" id="deliveryOption"
        value={this.props.value}
        invalid={this.props.invalid}
        onChange={(evt) => {
          const val = evt.target.value;
          if (val === 'pickup') {
            this.props.onChange(val, null);
          }
          else {
            const shippingOption = this.props.seller.shipping[parseInt(val)];
            this.props.onChange(val, {
              ...shippingOption,
              costGstExcl: shippingOption.cost / GST,
              costGstIncl: shippingOption.cost,
            });
          }
        }}
      >
        {this.props.seller.pickup && this.props.seller.pickup.allowPickup && <option value="pickup">Pick up in person</option>}
        {(this.props.seller.shipping || []).map((e, i) => (
          <option key={i} value={i}>{e.name} - {formatCost(auth.isWholesale() ? (e.cost / GST) : e.cost)}</option>
        ))}
      </Input>
    );
  }
};


export default class Shipping extends (FormBase) {
  constructor(props) {
    super(props);
    this.state = {
      _validation: {},
      deliveryOption: 'deliver',
    };
  }

  async loadSellerDetails() {
    if (this.props.sellerId) {
      const data = await api.callAPI(this, api.get(`seller/${this.props.sellerId}`), false);
      this.setState({seller: data});
    }
  }

  async componentDidMount() {
    if (auth.isAuthenticated()) {
      const userDetails = await api.get(`user`);
      const newState = {
        email: userDetails.email,
        name: userDetails.name,
        ...userDetails.shippingAddress,
      };
      this.setState(newState);
      this.props.onUpdate(newState);
    }
    this.loadSellerDetails();
  }

  componentDidUpdate(prevProps) {
    if (this.props.sellerId !== prevProps.sellerId) {
      this.loadSellerDetails();
    }
  }

  validate() {
    const errors = {};

    this.validateEmail('email', errors);
    this.validateRequired('name', errors);
    if (this.state.deliveryOption !== 'pickup') {
      this.validateRequired('deliveryDetails', errors);
      this.validateRequired('address', errors);
      this.validateRequired('addressCity', errors);
    }

    return errors
  }

  _updateParent() {
    // propagage changes in the form to the parent component
    this.props.onUpdate(reactStateToDoc(this.state));
    this.props.setValid(Object.keys(this.validate()).length === 0);
  }

  handleInputChange(event) {
    super.handleInputChange(event, this._updateParent.bind(this));
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({_validation: errors});

    if (Object.keys(this.validate()).length === 0) {
      this.props.onSubmit();
    }
  }

  render() {
    const FormInput = this.FormInput;
    return (
      <Form>
        {!auth.isAuthenticated() &&
          <h6 className="text-info">
            {'Want these details remembered? '}
            <Link to={{pathname: "/login", state: {prevLocation: "/checkout"}}}>Sign in</Link>
            {' or '}
            <Link to={{pathname: "/register", state: {prevLocation: "/checkout"}}}>create a new account</Link>!
          </h6>
        }
        <FormInput type="email" name="email"
          placeholder="you@example.com"
          label="Email"
          disabled={auth.isAuthenticated()}
        />

        <FormInput type="custom" name="deliveryOption" label="Delivery Method">
          <ShippingOptions
            seller={this.state.seller}
            value={this.state.deliveryOption}
            invalid={!!this.state._validation.deliveryOption}
            onChange={(val, details) => this.setState({
              deliveryOption: val,
              deliveryDetails: details,
            }, this._updateParent.bind(this))}
          />
        </FormInput>

        <FormInput type="text" name="name"
          label="Your Name"
        />

        {(this.state.deliveryOption !== "pickup") &&
          <React.Fragment>
            <FormInput type="text" name="address"
              label="Address"
            />
            <FormInput type="text" name="addressCity"
              label="City"
            />
          </React.Fragment>
        }

        {(this.state.deliveryOption === "pickup") && this.state.seller && this.state.seller.pickup &&
          <React.Fragment>
            {this.state.seller.pickup.pickupAddress &&
              <FormGroup row>
                <Label sm={2}>Pick up Address</Label>
                <Col sm={10} className="text-left pt-2">
                  {this.state.seller.pickup.pickupAddress}
                  <br/>
                  <iframe
                    title="Pick Up Address"
                    width="100%"
                    height="300"
                    frameBorder="0" style={{border:0}}
                    src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=${this.state.seller.pickup.pickupAddress}`}
                    allowFullScreen>
                  </iframe>
                </Col>
              </FormGroup>
            }
            {this.state.seller.pickup.pickupNotes &&
              <FormGroup row>
                <Label sm={2}>Notes</Label>
                <Col sm={10} className="text-left pt-2">
                  {this.state.seller.pickup.pickupNotes}
                </Col>
              </FormGroup>
            }
          </React.Fragment>
        }

        <Button
          color="primary"
          className="float-right mt-2 mb-2"
          onClick={this.handleSubmit}
        >
          Continue to Payment
        </Button>
      </Form>
    );

  }

};
